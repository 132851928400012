<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 2.3 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">2. Uw algemeen beleid</h3>
                        <h4>3. Voert uw organisatie een structureel preventiebeleid fysieke belasting?</h4>
                        <p class="mb-5">Het antwoord op deze vraag is belangrijk, maar wel vrij subjectief. Door middel van het doorlopen van de onderstaande vragen uit deze
                            BeleidsSpiegel krijgt u een beter beeld van de kwaliteit van uw beleid fysieke belasting. En dus ook van de verbeterpunten. </p>
                        <h5>3a. voor zorgverleners</h5>
                        <ChoiceField id="3.a"/>
                        <h5>3b. voor medewerkers van ondersteunde diensten</h5>
                        <ChoiceField id="3.b"/>
                    </div>
                </div>
                <!-- Question 2.4 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>4. Wordt er in elke begroting rekening gehouden met een reële post voor het terugdringen of aanvaardbaar houden van fysieke belasting?</h4>
                        <p class="mb-5">Om een succesvol fysieke belasting te kunnen voeren is het noodzakelijk hiervoor jaarlijks een reële post op te nemen in de begroting. </p>
                        <ChoiceField id="4.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet structueel',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['physicalStore/getQuestionById']('4.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('4.a').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">De omvang van de post hangt af van de problematiek die is gesignaleerd en de doelstellingen die op grond daarvan zijn
                                    geformuleerd.
                                    Belangrijk is echter dat hiervoor een basisbedrag gereserveerd wordt, dat jaarlijks op basis van monitoring (bijvoorbeeld door middel van de <a href="https://tilthermometer.nl" rel="nofollow"
                                                                                                                                                                                    target="_blank">TilThermometer</a>, <a
                                        href="https://risico-radar.nl" rel="nofollow" target="_blank">RisicoRadar</a> of BeleidsSpiegel) kan worden
                                    bijgesteld. Voor het doorrekenen van het effect van
                                    investeringen kunt u gebruik maken van businesscases.</p>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.5 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>5. Beschikt uw organisatie over een monitoringsysteem om het beleid fysieke belasting te ondersteunen?</h4>
                        <ChoiceField id="5.a"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['physicalStore/getQuestionById']('5.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('5.a').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tips</span>
                                <div>
                                    <p class="mb-3 sub">1. Het monitoren van het beleid fysieke belasting in uw organisatie kan op verschillende manieren: <a href="https://tilthermometer.nl" rel="nofollow"
                                                                                                                                                              target="_blank">TilThermometer</a> (monitort
                                        de
                                        blootstelling aan fysieke belasting bij zorgverleners), de <a
                                            href="https://risico-radar.nl" rel="nofollow" target="_blank">RisicoRadar</a> (monitort de blootstelling aan fysieke belasting
                                        bij
                                        medewerkers van ondersteunende diensten) en deze BeleidsSpiegel (monitort beleid). Maar u kunt bijvoorbeeld ook monitoren
                                        door
                                        middel van regelmatige besprekingen met ErgoCoaches of het uitzetten van een enquêtes onder de medewerkers. </p>
                                    <p class="mb-0 sub">2. Verzuimregistratie is niet voldoende, deze maat is niet gevoelig genoeg om uw ergonomiebeleid op te baseren en bij te
                                        sturen.
                                        Verzuim is een mogelijk eindresultaat van een variëteit aan gezondheids- en werkdrukproblemen, waaronder klachten aan het
                                        bewegingsapparaat. Ook verzuimen mensen vaak pas als ze al wat langer of ernstiger klachten hebben. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.6 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>6. Sluit het beleid fysieke belasting aan op het beleid van uw organisatie rondom bijvoorbeeld vallen, decubitus, incontinentie, onvrijwillige zorg,
                            etc.?</h4>
                        <p class="mb-5">Omdat aandachtsvelden als ergonomie, vallen, incontinentie, mobiliteit, voeding, onvrijwillige zorg, decubitus, etc. sterk met elkaar
                            samenhangen verdient het aanbeveling te streven naar synergie waardoor uw beleid effectiever wordt.</p>
                        <ChoiceField id="6.a"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['physicalStore/getQuestionById']('6.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('6.a').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">Dit <a href="https://www.locomotion.nl/admin/resources/030-032arb10art03knibbe.pdf" target="_blank"> artikel</a> gaat in op de
                                    mogelijkheden die ontstaan als we aandachtsvelden als ergonomie, vallen, incontinentie, mobiliteit, voeding, onvrijwillige zorg
                                    ,
                                    decubitus, etc. in beleid met elkaar verbinden. </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.7 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>7. Zijn de Praktijkrichtlijnen Fysieke Belasting de basis van het beleid fysieke belasting in uw organisatie?</h4>
                        <p class="mb-5">Zowel voor de zorgverleners als voor de medewerkers van ondersteunende diensten zijn de Praktijkrichtlijnen Fysieke Belasting vastgelegd in
                            de Arbocatalogus van uw zorgbranche. De NLA (arbeidsinspectie) controleert hierop. Deze grenzen voor fysieke belasting
                            (in feite het moment waarop fysieke belasting verandert in fysieke overbelasting) moeten echter wel voor iedereen duidelijk zijn en
                            bekrachtigd worden in het organisatiebeleid.</p>
                        <ChoiceField id="7.a"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['physicalStore/getQuestionById']('7.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('7.a').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">Kijk op uw Arbocatalogus voor de meeste recente versie van de Praktijkrichtlijnen Fysieke Belasting zoals die uw branche zijn
                                    afgesproken. En op <a href="https://free-learning.nl/modules/basisfysiekgezondwerken/start.html"
                                                          target="_blank">free-learning.nl</a> vindt u een Free Learning module 'Basis Fysiek Gezond Werken’ waarin
                                    de
                                    Praktijkrichtlijnen centraal staan.</p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-3'}" class="btn btn-grad mt-3">3. Borging deskundigheid<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step2',
    components: {ChoiceField, Progress}
}
</script>
