<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <div class="row justify-content-center" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <!-- Question 8.14 -->
                        <h3 class="color-primary">14. Zijn er voldoende hulpmiddelen beschikbaar? </h3>
                        <p class="mb-5">Voldoende' wil zeggen dat er voldoende hulpmiddelen zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen werken. Hoewel de <a
                            href="https://www.tilthermometer.nl" target="_blank">TilThermometer</a> het gebruik van de hulpmiddelen beoordeelt (en niet de aanwezigheid) zijn de
                                        resultaten van een meting met de TilThermometer zeker informatief op dit punt. Als ze worden gebruikt, zijn ze er immers.
                                        Houd er rekening mee dat het hier alleen gaat om hulpmiddelen die nodig zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen
                                        werken. Andere hulpmiddelen, denk aan het opstalooprek of het draaibed, kunnen de fysieke overbelasting aanvullend reduceren. Vaak ook
                                        indirect, doordat de zelfredzaamheid van de cliënt wordt gefaciliteerd. </p>
                        <ChoiceField id="14" />
                        <!-- Question 8.14a -->
                        <h4>14a. Elektrische hoog-laagbedden</h4>
                        <p class="mb-5">De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-2.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting</a> die
                                        hier van toepassing is luidt: als de cliënt Mobiliteitsklasse C, D of E heeft, dan moet een elektrisch hoog-laag bed in combinatie met een
                                        glijhulpmiddel worden gebruikt.</p>
                        <ChoiceField id="14.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over de Praktijkrichtlijnen Fysieke Belasting.</p>
                                        <a href="https://www.free-learning.nl/modules/basisfysiekgezondwerken/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14b -->
                        <h4>14b. Glijhulpmiddelen</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-5.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                            toepassing is luidt: als de cliënt Mobiliteitsklasse C, D of E heeft, dan moet een glijhulpmiddel (of vergelijkbaar hulpmiddel) in combinatie met een
                            elektrisch hoog-laag bed worden gebruikt.
                        </p>
                        <ChoiceField id="14.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over het werken met glijzeilen.</p>
                                        <a href="https://www.free-learning.nl/modules/werkenmetglijzeilen/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14c -->
                        <h4>14c. Staliften (actieve tilliften)</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-3.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                            toepassing is luidt: als de cliënt Mobiliteitsklasse C heeft, moet er een actieve (of sta-)lift worden gebruikt. De cliënt kan dan zelfstandig
                            (op)staan, heeft enige rompbalans en kan enigszins steun nemen op een of beide benen.
                        </p>
                        <ChoiceField id="14.c" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Staliften zijn in drie soorten: manueel over de vloer verrijdbaar, gemotoriseerd over de vloer verrijdbaar en hangend
                                                            aan
                                                            het plafond. Het manoeuvreren met gemotoriseerde liften en plafondliften kost nauwelijks kracht en heeft dus ergonomisch
                                                            gezien de voorkeur. <br /> Kijk op Free Learning voor een e-learning module over het werken met de stalift en de
                                                            passieve
                                                            lift.</p>
                                        <a href="https://www.free-learning.nl/modules/staliftenpassievelift/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14d -->
                        <h4>14d. Passieve tilliften</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-3.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                            toepassing is luidt: als de cliënt Mobiliteitsklasse D of E heeft, moet er een passieve lift gebruikt worden. De cliënt heeft dan onvoldoende rompbalans
                            en kan geen steun nemen op de benen.
                        </p>
                        <ChoiceField id="14.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Passieve liften zijn in drie soorten: manueel over de vloer verrijdbaar, gemotoriseerd over de vloer verrijdbaar en
                                                            hangend
                                                            aan het plafond. Het manoeuvreren met gemotoriseerde liften en plafondliften kost nauwelijks kracht en heeft dus
                                                            ergonomisch
                                                            gezien de voorkeur. <br /> Kijk op Free Learning voor een e-learning module over het werken met de stalift en de
                                                            passieve
                                                            lift.</p>
                                        <a href="https://www.free-learning.nl/modules/staliftenpassievelift/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14e -->
                        <h4>14e. Steunkousaan- en uittrekhulpmiddelen</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-1.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                            toepassing is luidt: bij het aan- en uittrekken van steunkousen van drukklasse 2 of hoger moet er een speciaal hulpmiddel worden gebruikt.
                        </p>
                        <ChoiceField id="14.e" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.e').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over het aan- en uittrekken van steunkousen.</p>
                                        <a href="https://www.free-learning.nl/modules/steunkousen/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14f -->
                        <h4>14f. Hoog-laag douchestoelen</h4>
                        <p class="mb-5">
                            De Praktijkrichtlijn Fysieke Belasting die hier van toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30°
                            gebogen of gedraaide romp of nek. In de praktijk betekent dit dat bij zorg bij zittend douchen een in hoogte verstelbare douchestoel gebruikt moet
                            worden.
                        </p>
                        <ChoiceField id="14.f" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.f').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                        <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14g -->
                        <h4>14g. Hoog-laag douchebrancards</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-2.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                            toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30° gebogen of gedraaide romp of nek. In de praktijk
                            betekent dit dat bij zorg bij liggend douchen een in hoogte verstelbare brancard moet worden gebruikt.
                        </p>
                        <ChoiceField id="14.g" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.g').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.g').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                        <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.14h -->
                        <h4>14h. Hoog-laag baden</h4>
                        <p class="mb-5">
                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-2.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                            toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30° gebogen of gedraaide romp of nek. In de praktijk
                            betekent dit dat bij zorg bij baden een in hoogte verstelbaar bad moet worden gebruikt.
                        </p>
                        <ChoiceField id="14.h" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'n.v.t',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('14.h').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.h').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                        <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.15 -->
                        <h4>15. Wordt er minstens eenmaal per jaar bekeken of er voldoende (til)hulpmiddelen zijn?</h4>
                        <ChoiceField id="15.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Nee, minder dan 1x per jaar',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee, nooit',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('15.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('15.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Om een redelijke indicatie te hebben of er nog voldoende (til)hulpmiddelen zijn is het zinvol de TilThermometer minstens
                                                            één
                                                            keer per jaar in te vullen.
                                                            Daarnaast laat de TilThermometer zien of de lichamelijke zorgzwaarte is veranderd. U kunt zo de vinger aan de pols
                                                            houden,
                                                            investeringen beter plannen en het beleid op het gebied van training en instructie zo nodig tijdig aanpassen. De
                                                            inspectie
                                                            kan vragen naar een actuele TilThermometer.</p>
                                        <a href="https://tilthermometer.nl" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.16 -->
                        <h4>16. Worden deze hulpmiddelen goed onderhouden?</h4>
                        <p class="mb-5">
                            Onvoldoende onderhoud kan leiden tot gevaarlijke situaties voor de cliënt (een val uit een tillift) of extra belasting voor de zorgverleners (hard duwen
                            omdat de wieltjes niet goed draaien). <br>
                            Het mag niet voorkomen dat een hulpmiddel (of bepaalde mogelijkheden van een hulpmiddel) niet gebruikt kunnen worden om reden van mechanische
                            mankementen, die terug te voeren zijn op onvoldoende onderhoud. Daarom is het verstandig afspraken over onderhoud te borgen in het reguliere beleid.
                            <br>
                            Voor tilliften en de bijbehorende slings geldt dat ze jaarlijks aantoonbaar moeten worden onderhouden in een - al dan niet digitaal - logboekje.
                        </p>
                        <ChoiceField id="16.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Nee, minder dan 1x per jaar',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee, nooit',
                                class: 'false'
                            }]" />
                        <!-- Question 8.17 -->
                        <h4>17. Wordt er minstens eenmaal per jaar bekeken of er voldoende tilhulpmiddelen en werkmaterialen zijn voor medewerkers van ondersteunende diensten?</h4>
                        <p class="mb-5">
                            Gezien de enorme diversiteit in hulpmiddelen voor medewerkers van ondersteunende diensten, is geen instrument beschikbaar waarmee dit zou kunnen worden
                            getoetst. De vuistregel is dat iedereen over voldoende hulpmiddelen moet beschikken om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen
                            werken.
                        </p>
                        <ChoiceField id="17.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Nee, minder dan 1x per jaar',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee, nooit',
                                class: 'false'
                            }]" />
                        <!-- Question 8.18 -->
                        <h4>18. Worden deze hulpmiddelen en werkmaterialen goed onderhouden?</h4>
                        <p class="mb-5">
                            Het mag niet voorkomen dat een hulpmiddel (of bepaalde mogelijkheden van een hulpmiddel) niet gebruikt kunnen worden om reden van mechanische
                            mankementen, die terug te voeren zijn op onvoldoende onderhoud. Voor klasse 1 hulpmiddelen (zoals tilliften en tilbanden) geldt dat ze jaarlijks
                            aantoonbaar onderhouden moeten worden.
                        </p>
                        <ChoiceField id="18.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Nee, minder dan 1x per jaar',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee, nooit',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('18.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('18.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Omdat het anders snel van de prioriteitenlijst verdwijnt, is het verstandig afspraken over onderhoud te borgen in
                                                            regulier
                                                            beleid. </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 8.19 -->
                        <h4>19. Wordt bij de aanschaf van nieuw materiaal nauwgezet bekeken of er volgens de Praktijkrichtlijnen Fysieke Belasting mee gewerkt kan worden?</h4>
                        <p class="mb-5">
                            Bij de aanschaf van nieuw werkmateriaal is het nodig te checken of er met het materiaal conform de Praktijkrichtlijnen Fysieke Belasting gewerkt kan
                            worden.
                        </p>
                        <ChoiceField id="19.a" :options="[{
                                value: 1,
                                label: 'Ja, standaard',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Zo nu en dan, niet standaard',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee, zelden of nooit',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('19.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('19.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Neem dit punt standaard op in het programma van eisen.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-9'}" class="btn btn-grad mt-3">9. Ergonomie van de omgeving<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step8',
    components: { ChoiceField, Progress }
}
</script>
