<script>
import {Radar} from 'vue-chartjs';

export default {
    extends: Radar,
    methods: {
        getAverageScore(questions) {
            return questions.map((question) => {
                let q = this.$store.getters['physicalStore/getQuestionById'](question);
                return q.value !== null ? q.value : 0;
            }).reduce((a, b) => a + b, 0) / questions.length;
        }
    },
    mounted() {
        this.renderChart({
                labels:   ['1. Beleid algemeen', '2. Praktijkrichtlijnen', '3. Organisatie fysiek', '4. Protocollair werken', '5. Bijzondere situaties', '6. Training en instructie', '7. Blootstelling', '8. Onderhoud', '9. Ergonomie'],
                datasets: [{
                    label:           'Landelijk VVT',
                    borderColor:     '#A1BC25',
                    backgroundColor: 'rgba(161, 188, 37, 0.3)',
                    data:            [0.6492, 0.5785, 0.7016, 0.6157, 0.3734, 0.6335, 0.64, 0.9581, 0.4738]
                }, {
                    label:           'Uw score',
                    borderColor:     'rgb(237, 0, 41)',
                    backgroundColor: 'rgba(237, 0, 41, 0.3)',
                    data:            [
                        this.getAverageScore(['3.a', '3.b', '4.a', '5.a']),
                        this.getAverageScore(['7.a', '9.a', '10.a']),
                        this.getAverageScore(['8.a', '8.b', '8.c', '8.d', '8.e', '8.f']),
                        this.getAverageScore(['9.a', '9.b', '9.c', '9.d', '9.e', '9.f']),
                        this.getAverageScore(['11.a', '11.b', '11.c', '11.d', '11.e', '11.f', '11.g', '11.h', '11.i', '11.j', '11.k', '11.l', '11.m', '11.n']),
                        this.getAverageScore(['12.c', '12.d', '12.e', '12.f']),
                        this.getAverageScore(['14.a', '14.b', '14.c', '14.d', '14.e', '14.f', '14.g', '14.h']),
                        this.getAverageScore(['16.a', '18.a']),
                        this.getAverageScore(['19.a', '20.a'])
                    ]
                }]
            },
            {}
        )
    }
}
</script>
