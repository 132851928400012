<template>
    <div v-if="this.$route.path !== '/'">
        <i id="scroll-indicator"></i>
        <i id="top"></i>
        <nav class="navbar navbar-expand-xl" id="mainNav">
            <router-link to="/" class="navbar-brand"><img :src="'/img/' + this.logo" class="logo"></router-link>
            <button class="navbar-toggler navbar-toggler-right text-white collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span> </span>
                <span> </span>
                <span> </span>
            </button>
            <div class="collapse navbar-collapse flex-row-reverse" id="navbarResponsive">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link :to="{name: addPrefix() + 'about'}">Over de BeleidsSpiegel</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: addPrefix() + 'faq'}">Veelgestelde vragen</router-link>
                    </li>
                    <li class="dropdown nav-item">
                        <a class="dropdown-toggle" href="#" id="productsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Stappen
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="productsDropdown">
                            <router-link v-for="(item, key) in this.getSteps()" v-bind:key="key" class="dropdown-item" :to="{name: item.route}">
                                <span class="nav-step">{{ item.step }}</span>
                                {{ item.title }}
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            logo: this.$route.path.startsWith('/fysieke-belasting') ? 'logo-fb.svg' : 'logo-pa.svg'
        }
    },
    methods: {
        addPrefix() {
            return !this.$route.path.startsWith('/fysieke-belasting') ? 'psychosocial-' : '';
        },
        getSteps() {
            if(this.$route.path.startsWith('/fysieke-belasting')) {
                return [
                    { step: '-', route: 'step-0', title: 'Introductie' },
                    { step: 1, route: 'step-1', title: 'Uw huidige situatie' },
                    { step: 2, route: 'step-2', title: 'Uw algemeen beleid' },
                    { step: 3, route: 'step-3', title: 'Borging deskundigheid' },
                    { step: 4, route: 'step-4', title: 'Vertaling Praktijkrichtlijnen naar zorghandelingen' },
                    { step: 5, route: 'step-5', title: 'Vertaling Praktijkrichtlijnen naar niet-cliënt gebonden handelingen' },
                    { step: 6, route: 'step-6', title: 'Bijzondere situaties' },
                    { step: 7, route: 'step-7', title: 'Training en instructie' },
                    { step: 8, route: 'step-8', title: 'Hulpmiddelen' },
                    { step: 9, route: 'step-9', title: 'Ergonomie van de omgeving' },
                    { step: '-', route: 'step-results', title: 'Resultaten', class: 'bold' }
                ]
            } else {
                return [
                    { step: '-', route: 'psychosocial-step-0', title: 'Introductie' },
                    { step: 1, route: 'psychosocial-step-1', title: 'Uw huidige situatie: De RI&E als startpunt' },
                    { step: 2, route: 'psychosocial-step-2', title: 'Uw algemene PSA-beleid' },
                    { step: 3, route: 'psychosocial-step-3', title: 'Het veiligheidsklimaat in uw organisatie' },
                    { step: 4, route: 'psychosocial-step-4', title: 'Psychosociale arbeidsbelasting en de subthema\'s' },
                    { step: 5, route: 'psychosocial-step-5', title: 'Training en instructie' },
                    { step: 6, route: 'psychosocial-step-6', title: 'Overgang' },
                    { step: 7, route: 'psychosocial-step-7', title: 'Ouder worden en PSA-beleid' },
                    { step: 8, route: 'psychosocial-step-8', title: 'Werkruimtes en veiligheid' },
                    { step: 9, route: 'psychosocial-step-9', title: 'Begroting' },
                    { step: '-', route: 'psychosocial-step-results', title: 'Resultaten', class: 'bold' }
                ]
            }
        }
    }
};
</script>