<template>
    <div class="btn-group" role="group">
        <button @click="selectOption($event)" v-bind:key="option.id" v-for="option in selectableOptions" type="button" :data-value="option.value" :data-text-value="option.label"
                :class="'btn btn-' + option.class">
            {{ option.label }}
        </button>
    </div>
</template>

<script>
export default {
    name:  'ChoiceField',
    props: ['id', 'options'],
    data() {
        return {
            storeType: this.$route.path.startsWith('/fysieke-belasting') ? 'physicalStore' : 'psychosocialStore'
        }
    },
    methods:  {
        selectOption(event) {
            this.$el.querySelectorAll('button').forEach(element => {
                element.classList.remove('active');
            });
            event.currentTarget.classList.add('active');

            this.$store.commit(this.storeType + '/setQuestion', {
                id:        this.id,
                value:     parseFloat(event.currentTarget.dataset.value),
                textValue: event.currentTarget.dataset.textValue
            });

            this.$store.commit(this.storeType + '/setPercentageDone');
        }
    },
    computed: {
        selectableOptions() {
            if(typeof this.options !== 'undefined') {
                return this.options;
            }
            return [{
                value: 1,
                label: 'Ja',
                class: 'true'
            }, {
                value: 0,
                label: 'Nee',
                class: 'false'
            }]
        }
    },
    mounted() {
        let question = this.$store.getters[this.storeType + '/getQuestionById'](this.id);
        if(question.value !== null) {
            this.$el.querySelectorAll('[data-value]').forEach(element => {
                if(parseFloat(element.dataset.value) === parseFloat(question.value)) {
                    element.classList.add('active');
                }
            });
        }
    }
}
</script>