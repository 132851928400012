var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h4',[_vm._v("20. Zijn de ruimtes en de inrichting daarvan geschikt om gezond te kunnen werken?")]),_c('p',{staticClass:"mb-5"},[_vm._v(" De werkruimte en de inrichting mogen geen beperking zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen werken. ")]),_c('ChoiceField',{attrs:{"id":"20.a","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Deels',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'step-results'}}},[_vm._v("Resultaten"),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = []

export { render, staticRenderFns }