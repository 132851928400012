<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 1.1 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">1. Uw huidige situatie</h3>
                        <h4>1. Komt fysieke belasting als een knelpunt naar voren uit de RI&E?</h4>
                        <p class="mb-5">Een RI&E signaleert globaal Arbo risico’s, zoals fysieke belasting. Als dat zo blijkt te zijn, is het relevant om uw beleid fysieke belasting te checken en gaat uw met deze BeleidsSpiegel, de
                            <a href="https://tilthermometer.nl" rel="nofollow" target="_blank">TilThermometer</a> en/of de <a href="https://risico-radar.nl" rel="nofollow" target="_blank">RisicoRadar</a> de diepte in.</p>
                        <h5>1a. voor zorgverleners</h5>
                        <ChoiceField id="1.a" :options="[{
                                value: 0,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <h5>1b. voor medewerkers van ondersteunde diensten</h5>
                        <ChoiceField id="1.b" :options="[{
                                value: 0,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="((this.$store.getters['physicalStore/getQuestionById']('1.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('1.a').value !== null) || (this.$store.getters['physicalStore/getQuestionById']('1.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('1.b').value !== null))">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">Er zijn geen wettelijke eisen gesteld aan de frequentie waarmee een RI&E moet worden uitgevoerd. De eis is dat de RI&E
                                    ‘actueel’
                                    moet zijn. De RI&E kan eventueel ook op onderdelen (bijvoorbeeld alleen bepaalde afdelingen of werkplekken) geactualiseerd
                                    worden.</p>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 1.2 -->
                <div class="row mb-4" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2. Beschikt u over een actuele TilThermometer?</h4>
                        <p class="mb-5">De noodzaak tot het voeren van een preventiebeleid fysieke overbelasting hangt nauw samen met de mobiliteit van de cliënten. Nog
                            belangrijker is in hoeverre u bij immobiele cliënten adequate maatregelen heeft getroffen om fysieke overbelasting bij zorgverleners
                            te
                            voorkomen. De TilThermometer geeft aan in hoeverre dit het geval is. </p>
                        <ChoiceField id="2.a"/>
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['physicalStore/getQuestionById']('2.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('2.a').value !== null">
                        <div class="col-md-4">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">Vul uw TilThermometer in via www.tilthermometer.nl</p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-2'}" class="btn btn-grad mt-3">2. Uw algemeen beleid<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step1',
    components: {ChoiceField, Progress},
}
</script>
