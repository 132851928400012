var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("3. Verankering deskundigheid fysieke belasting.")]),_c('h4',[_vm._v("8a. Is er een platform, commissie of werkgroep die zich bezighoudt met het beleid fysieke belasting?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om het beleid fysieke belasting voortdurend te kunnen bijsturen is communicatie tussen beleidsmakers en uitvoerenden essentieel. Het ligt voor de hand daarvoor een werkgroep of commissie in het leven te roepen. Naar mate het beleid beter is geïmplementeerd kan deze groep wat meer op de achtergrond actief worden en vooral beschikbaar zijn als vraagbaak bij probleemsituaties.")]),_c('ChoiceField',{attrs:{"id":"8.a"}}),_c('h4',[_vm._v("8b. Zijn er direct op de werkvloer ErgoCoaches actief?")]),_c('p',{staticClass:"mb-5"},[_vm._v("ErgoCoaches zijn 'gewone' medewerkers die een extra verantwoordelijkheid en deskundigheid hebben op het gebied van fysieke belasting. Ze worden ook wel ‘aandachtsvelders fysieke belasting’ genoemd. Het is niet verplicht met ErgoCoaches te werken, maar de constructie is in de opstartfase van het beleid bewezen effectief. Later kunnen ErgoCoaches een belangrijke rol spelen bij het borgen van het beleid.")]),_c('ChoiceField',{attrs:{"id":"8.b"}}),_c('h4',[_vm._v("8c. Hebben deze ErgoCoaches voldoende kennis en vaardigheden?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om de kwaliteit van de ErgoCoaches op een voldoende hoog niveau te houden is het belangrijk ze initieel te scholen, regelmatig bij te scholen, ze de mogelijkheid te geven om onderling informatie uit te wisselen en om seminars, studiedagen en beurzen te bezoeken.")]),_c('ChoiceField',{attrs:{"id":"8.c","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Globaal',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('8.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.c').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Uit "),_c('a',{attrs:{"href":"https://www.locomotion.nl/admin/resources/koppelaar-knibbe.pdf","target":"_blank"}},[_vm._v("onderzoek")]),_vm._v(" blijkt dat het hebben van protocollen in de zorgdossiers de rol van de ErgoCoaches versterkt en effectiever maakt.")])])])]):_vm._e()]),_c('h4',[_vm._v("8d. Hebben de ErgoCoaches voldoende mogelijkheden om problemen op te lossen?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Het is belangrijk dat de ErgoCoaches de mogelijkheid hebben om zelf, of samen met collega's, ergonomische problemen op te lossen. Managers moeten ruimte geven en het vertrouwen uitstralen dat de ErgoCoaches zelf in staat zijn de problemen met hun collega’s op te lossen.")]),_c('ChoiceField',{attrs:{"id":"8.d","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Globaal',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('8.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.d').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("De Voilà!-methode is een goede manier om ErgoCoaches in hun kracht te zetten. Kijk op de website van "),_c('a',{attrs:{"href":"https://www.locomotion.nl/voila-methode","target":"_blank"}},[_vm._v("locomotion.nl")]),_vm._v(" voor achtergrondinformatie, films, cartoons, posters etc.")])])])]):_vm._e()]),_c('h4',[_vm._v("8e. Hebben deze ErgoCoaches geregeld onderling overleg?")]),_vm._m(0),_c('ChoiceField',{attrs:{"id":"8.e","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Niet structueel',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('h4',[_vm._v("8f. Hebben deze ErgoCoaches voldoende tijd om hun werk te kunnen doen?")]),_c('p',{staticClass:"mb-5"},[_vm._v("De ErgoCoaches moeten in het begin structureel vrij geroosterd kunnen worden om hun taken naar behoren te kunnen uitvoeren. De hoeveelheid tijd die hiervoor nodig is hangt af van hun taken, verantwoordelijkheid en de fase waarin het beleid fysieke belasting zich bevindt. We gaan uit van minimaal een dagdeel per week in teams die fysiek belast kunnen worden. Dit onderstreept het belang van de taak en voorkomt dat de taak onderaan de prioriteitenlijst belandt. Later kan dit minder worden. Het is wel aan te bevelen om een bepaalde ondergrens hanteren zodat de ErgoCoaches minstens hun functie als ergonomische waakhond kunnen blijven uitvoeren.")]),_c('ChoiceField',{attrs:{"id":"8.f","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Niet structueel',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'step-4'}}},[_vm._v("4. Vertaling Praktijkrichtlijnen naar zorghandelingen"),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-5"},[_vm._v("Afhankelijk van de exacte taken en verantwoordelijkheden moeten de ErgoCoaches geregeld bij elkaar kunnen komen. Bij de start van het beleid zal er wat vaker overlegd moeten worden dan in het verloop van de beleidsuitvoering. Kijk "),_c('a',{attrs:{"href":"/uploads/ergocoaches-in-beeld.pdf","target":"_blank"}},[_vm._v("hier")]),_vm._v(" voor meer achtergrondinformatie over het werken met ErgoCoaches.")])}]

export { render, staticRenderFns }