var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("9. Begroting")]),_vm._m(0),_c('h4',[_vm._v("Wordt er in elke begroting rekening gehouden met een reële post voor het terugdringen of aanvaardbaar houden van psychosociale arbeidsbelasting?")]),_c('ChoiceField',{attrs:{"id":"9.a","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Niet structureel',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['psychosocialStore/getQuestionById']('9.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('9.a').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('div',{staticClass:"sub text-justify"},[_c('p',{staticClass:"mb-0"},[_vm._v(" De omvang van de post hangt af van de problematiek die is gesignaleerd en de doelstellingen die op grond daarvan zijn geformuleerd. Belangrijk is echter dat hiervoor een basisbedrag gereserveerd wordt, dat jaarlijks kan worden geëvalueerd en bijgesteld. ")])])])])]):_vm._e()])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'psychosocial-step-results'}}},[_vm._v("Resultaten"),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5"},[_c('span',{staticClass:"color-yellow rotate heading-font bold"},[_vm._v("Toelichting")]),_c('div',{staticClass:"p-5"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Om een succesvol beleid psychosociale arbeidsbelasting te kunnen voeren is het belangrijk hiervoor jaarlijks een reële post op te nemen in de begroting of uren te oormerken voor dit doel. ")])])])}]

export { render, staticRenderFns }