<template>
    <div>
        <section class="bg-circles pb-0 pt-12 pt-xs-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 order-2 order-md-1" data-aos="fade-right">
                        <h1 class="large">Privacyverklaring</h1>
                        <p>
                            <strong>Bedrijfs- en organisatiegegevens</strong><br>
                            Hoewel u deze website ook als bedrijf of zorgorganisatie kunt bezoeken en gebruiken, vallen bedrijfsgegevens niet onder de Wet bescherming persoonsgegevens (Wbp). Dit
                            privacy statement is dan ook hoofdzakelijk bedoeld voor diegenen die de website als particulier, werknemer of werkgever gebruiken.
                            Vastleggen en verwerking van gegevens
                            Door het gebruiken van onze website kunt u gegevens bij ons achterlaten. Dit kunnen persoonsgegevens zijn. Persoonsgegevens kunt u zowel op actieve als op passieve wijze
                            aan ons verstrekken. Bij actief verstrekte gegevens gaat het om gegevens die u zelf op de website invult of bijvoorbeeld per e-mail aan ons toestuurt. Daarnaast kunnen er
                            op de website automatisch gegevens worden opgeslagen, soms zonder dat u zich daarvan bewust bent (passief verstrekte gegevens). In dit privacy statement vindt u welke
                            actief en passief verstrekte gegevens precies worden verwerkt en voor welke doeleinden. Gegevens die niet langer nodig zijn worden verwijderd. U heeft het recht om te
                            vragen om inzage in, correctie of verwijdering van uw gegevens.
                        </p>
                        <p>
                            <strong>Persoonsgebonden of organisatiegegevens worden niet opgeslagen</strong><br>
                            Voor het gebruik van de BeleidsSpiegel worden geen persoonsgebonden gegevens opgeslagen. U kunt als u klaar bent met invullen een pdf laten maken en deze downloaden. Het
                            betekent wel dat als u dat niet doet, uw gegevens niet meer te herleiden zijn of opnieuw op te roepen zijn: ook niet door ons. We slaan dit namelijk niet op. We raden u
                            daar nadrukkelijk aan om op deze pdf te downloaden voordat u deze website afsluit en uw browser sluit.
                            Verstrekking aan derden
                        </p>
                        <p>
                            Wij verstrekken de door u aan ons verstrekte gegevens niet aan derden, tenzij dit noodzakelijk is in het kader van een overeenkomst die u met ons sluit of wanneer dit
                            wettelijk verplicht is. In een geval van een vermoeden van fraude of misbruik van onze website kunnen wij persoonsgegevens aan de bevoegde autoriteiten overhandigen.
                        </p>
                        <p>
                            <strong>Beveiliging van gegevens</strong><br>
                            Wij maken gebruik van veiligheidsprocedures, onder meer om te voorkomen dat onbevoegden toegang krijgen tot (persoons)gegevens.
                        </p>
                        <p>
                            <strong>Gebruik van cookies</strong><br>
                            Wij maken op onze website gebruik van cookies. Daarmee kunnen wij onder andere het bezoek van pagina’s van de website combineren en het gedrag van gebruikers analyseren. U
                            kunt het gebruik van deze cookies weigeren, hoewel dit de functionaliteit en het gebruiksgemak van de website kan beperken.
                        </p>
                        <p>
                            <strong>Diensten van Google</strong><br>
                            Onze website maakt gebruik van bepaalde diensten van Google waarbij gebruik kan worden gemaakt van cookies en andere technieken om bij te houden hoe gebruikers de website
                            gebruiken. De aldus verkregen informatie wordt door Google opgeslagen op hun servers in de Verenigde Staten.
                            Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt, om rapporten over de website te kunnen maken en om haar adverteerders informatie over de
                            effectiviteit van campagnes te kunnen bieden. Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de
                            informatie namens Google verwerken. Wij hebben hier geen invloed op.
                        </p>
                        <p>
                            <strong>Websites van derden</strong><br>
                            Dit privacy statement is niet van toepassing op websites van derden die door middel van links met onze website zijn verbonden. Wij kunnen niet garanderen dat deze derden op
                            een
                            betrouwbare of veilige manier met uw persoonsgegevens omgaan. Wij raden u dan ook aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te
                            maken.
                        </p>
                        <p>
                            <strong>Wijziging Privacy Statement</strong><br>
                            Wij behouden ons het recht voor om wijzigingen aan te brengen in dit privacy statement.
                        </p>
                        <p>
                            <strong>Contactgegevens</strong><br>
                            Vragen over ons privacybeleid of vragen omtrent inzage en wijzingen in (of verwijdering van) uw persoonsgegevens kunt u te allen tijde richten aan: j.j.knibbe@gmail.com.
                            Wij
                            staan ingeschreven bij de Kamer van Koophandel Centraal Gelderland 09080.147
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'PrivaycStatement',
}
</script>