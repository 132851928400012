<template>
    <div>
        <section class="fullscreen-85 bg-circles pb-0 pt-xs-0">
            <div class="container v-align-transform">
                <div class="row align-items-center">
                    <div class="col-12 order-2 order-md-1" data-aos="fade-right">
                        <h1 class="large">Copyright</h1>
                        <p>
                            Niets van deze website mag zonder voorafgaande schriftelijke toestemming van de auteurs en uitgever gebruikt worden voor overname in andere gedrukte, geprinte, digitale of
                            welke andere vorm dan ook zonder voorafgaande schriftelijke toestemming. De inhoud van deze website is met de grootste zorgvuldigheid samengesteld. Noch de auteurs, noch de
                            uitgever stellen zich echter aansprakelijk voor eventuele schade als gevolg van eventuele onjuistheden en/of onvolkomenheden.
                        </p>
                        <p>
                            Opdrachtgever: RegioPlus, Zoetermeer<br>
                            Copyright: LOCOmotion<br>
                            Auteurs: Knibbe JJ, Knibbe NE. LOCOmotion<br>
                            Bouw en design: BenedenBoven<br>
                            Voor vragen kunt u contact opnemen met: <a href="mailto:j.j.knibbe@gmail.com">j.j.knibbe@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <Partner />
    </div>
</template>

<script>
import Partner from "../defaults/Partners";

export default {
    name:       'Copyright',
    components: { Partner }
}
</script>