<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 6.11 -->
                <div class="row justify-content-center" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">11. Is er in uw organisatie beleid op schrift aanwezig ten aanzien van fysieke belasting in bijzondere gevallen?</h3>
                        <!-- Question 6.11ab -->
                        <h4>11a. Voor het omgaan met agressieve cliënten?</h4>
                        <ChoiceField id="11.a"/>
                        <h4>11b Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.b"/>
                        <p class="mb-5">Agressie door cliënten in de basiszorg kan het risico op het krijgen van klachten aan het bewegingsapparaat verhogen.</p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Op Blijf Inzetbaar <a href="https://www.blijfinzetbaar.nl/" target="_blank">blijfinzetbaar.nl</a> vindt u relevant en
                                            praktisch materiaal om agressie in de basiszorg te voorkomen. Kijk ook op <a href="https://www.free-learning.nl"
                                                                                                                         target="_blank">Free Learning</a> voor
                                            e-learning modules over het voorkomen en omgaan met agressie.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 6.11cd -->
                        <h4>11c. Voor fysiek zwaar werk voor zwangeren?</h4>
                        <ChoiceField id="11.c"/>
                        <h4>11d Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.d"/>
                        <p class="mb-5">Voor fysiek zwaar werk voor zwangeren zijn zorgorganisaties gehouden aan wettelijke bepalingen en regelgeving. De Inspectie SZW legt deze
                            wet als volgt uit: gedurende de zwangerschap en eerste maanden na de bevalling mogen vrouwen niet worden verplicht frequent (dat wil zeggen
                            meer dan eenmaal per uur) meer dan vijf kilo te tillen of te dragen. Voor meer incidentele activiteiten geldt dat vrouwen in de
                            bovengenoemde periode niet verplicht kunnen worden meer dan 10 kilo te tillen of te dragen.</p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Arbocatalogus VVT voor meer informatie over zwangerschap en fysieke belasting.</p>
                                        <a href="https://www.arbocatalogusvvt.nl/thema/zwangerschap-en-arbeid/fysieke-belasting-en-zwangerschap/algemeen/" class="btn btn-hints"
                                           target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 6.11ef -->
                        <h4>11e. Voor fysiek zwaar werk voor oudere werknemers?</h4>
                        <ChoiceField id="11.e"/>
                        <h4>11f. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.f"/>
                        <p class="mb-5">Gezien de stijgende leeftijd van de zorgverleners en de daaraan gekoppelde extra risico’s op verzuim en uitstroom, is behoud van medewerkers
                            en dus werklastbeheersing en leeftijdsbewust vitaliteits- en personeelsbeleid steeds noodzakelijker. </p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.f').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            'BlijfGezond’ is een praktisch boekje voor zorgverleners, onder andere over de uitdagingen van het ouder worden in de zorg. Bestel het boekje via <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                                                                                                 target="_blank">locomotion.nl/winkeltje</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 6.11gh -->
                        <h4>11g. Voor het verplaatsen (tillen) van gevallen cliënten?</h4>
                        <ChoiceField id="11.g"/>
                        <h4>11h. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.h"/>
                        <p class="mb-5">Ook voor de transfer van een gevallen cliënt geldt de norm van 23 kilo. Afhankelijk van de toestand van de cliënt en de situatie waarin hij
                            of zij verkeert, kan het noodzakelijk zijn een tillift te gebruiken. </p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.h').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.h').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Maak heldere afspraken over hoe ergonomisch te handelen bij een gevallen cliënt. Kijk voor films met tips over vallen op
                                            GoedGebruik. Ook zijn er twee Free Learning modules over dit onderwerp: ‘Valpreventie’ en ‘Na een val’.</p>
                                        <a href="https://www.goedgebruik.nl" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 6.11ij -->
                        <h4>11i. Voor het verplaatsen (tillen) en de zorg voor overleden cliënten?</h4>
                        <ChoiceField id="11.i"/>
                        <h4>11j. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.j"/>
                        <p class="mb-5">Voor het verplaatsen van overleden cliënten is het nodig een hulpmiddel te gebruiken.</p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.j').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.j').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Maak vaste afspraken over het ergonomisch verplaatsen en de zorg voor overleden cliënten. Ook om te voorkomen dat er
                                            discussie ontstaat in situaties waarin dat niet gewenst of minder respectvol is.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 6.11kl -->
                        <h4>11k. Voor werkhervatting na verzuim als gevolg van rugklachten?</h4>
                        <ChoiceField id="11.k"/>
                        <h4>11l. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.l"/>
                        <p class="mb-5">Werkhervatting bij verzuim als gevolg van rugklachten moet zo snel mogelijk plaatsvinden, en met de juiste maatregelen. Het beleid moet er
                            op zijn gericht medewerkers zo snel mogelijk te activeren en in beweging te houden. Wanneer de medewerker echter nog niet in staat is alle
                            fysiek belastende handelingen te verrichten, mag dit niet betekenen dat collega’s deze extra belasting op zich nemen.</p>
                        <!-- Question 6.11mn -->
                        <h4>11m. Voor het omgaan met extreem obese cliënten?</h4>
                        <ChoiceField id="11.m"/>
                        <h4>11n. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                        <ChoiceField id="11.n"/>
                        <p class="mb-5">Cliënten worden steeds zwaarder. Zo zwaar dat sommige hulpmiddelen (hoog-laag bedden, tilliften, brancards, etc.) hier niet tegen bestand
                            zijn. Dan moet het duidelijk zijn hoe gehandeld moet worden, welke hulpmiddelen wel geschikt zijn en hoe je die ter plekke kunt krijgen. Het
                            is nodig dat de zorgorganisatie een nadere specificatie van het preventiebeleid fysieke belasting heeft, gericht op het ergonomisch en
                            respectvol omgaan met obese tot zeer obese cliënten (> 120 kg).</p>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('11.n').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.n').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Op <a href="https://www.locomotion.nl/bmi40plus" target="_blank">deze website</a> vindt u uitgebreide informatie over
                                            ergonomische en respectvolle zorg voor cliënten met ernstig overgewicht: films, brochures, cartoons, checklists en
                                            artikelen. Kijk op <a href="https://www.free-learning.nl/modules/obeseclienten/start.html" target="_blank">Free
                                                Learning</a>
                                            voor een e-learning module over het omgaan met extreem zware cliënten. </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-7'}" class="btn btn-grad mt-3">7. Training en instructie<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step6',
    components: {ChoiceField, Progress}
}
</script>
