<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <div class="row justify-content-center" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">9. Begroting</h3>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Om een succesvol beleid psychosociale arbeidsbelasting te kunnen voeren is het belangrijk hiervoor jaarlijks een reële post op te nemen in de begroting of uren te
                                    oormerken voor dit doel.
                                </p>
                            </div>
                        </div>
                        <h4>Wordt er in elke begroting rekening gehouden met een reële post voor het terugdringen of aanvaardbaar houden van psychosociale arbeidsbelasting?</h4>
                        <ChoiceField id="9.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet structureel',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['psychosocialStore/getQuestionById']('9.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('9.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <div class="sub text-justify">
                                            <p class="mb-0">
                                                De omvang van de post hangt af van de problematiek die is gesignaleerd en de doelstellingen die op grond daarvan zijn geformuleerd. Belangrijk is echter
                                                dat hiervoor een basisbedrag gereserveerd wordt, dat jaarlijks kan worden geëvalueerd en bijgesteld.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-results'}" class="btn btn-grad mt-3">Resultaten<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep9',
    components: { ChoiceField, Progress }
}
</script>
