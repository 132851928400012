var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("7. Ouder worden en PSA-beleid")]),_vm._m(0),_c('h4',[_vm._v("Is er in uw organisatie beleid op schrift aanwezig ten aanzien van ouder wordende werknemers?")]),_c('ChoiceField',{attrs:{"id":"7.a"}}),_c('h4',[_vm._v("Wordt dit altijd / meestal in praktijk gebracht?")]),_c('ChoiceField',{attrs:{"id":"7.b","options":[{
                            value: 1,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: 'Deels',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[((this.$store.getters['psychosocialStore/getQuestionById']('7.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.a').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('7.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.b').value !== null))?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('div',{staticClass:"sub text-justify"},[_c('ol',[_c('li',[_vm._v(" Gezond & Zeker heeft het BlijfGezond boekje uitgebracht waarin expliciet aandacht is voor de ouder wordende zorgmedewerker. Het kan daar besteld worden ("),_c('a',{attrs:{"href":"https://gezondenzeker.nl","target":"_blank"}},[_vm._v("gezondenzeker.nl")]),_vm._v(") ")]),_c('li',[_c('a',{attrs:{"href":"https://www.arboportaal.nl/onderwerpen/ouderen","target":"_blank"}},[_vm._v("Op deze site")]),_vm._v(" treft u meer achtergrondinformatie en tips aan. ")]),_c('li',[_vm._v(" Door het hanteren van à-la-carte-systemen (keuzemenu’s) kunnen werknemers aangeven wat zij nodig hebben om gezond ouder te blijven werken. Enkele voorbeelden (zie "),_c('a',{attrs:{"href":"https://arboportaal.nl","target":"_blank"}},[_vm._v("arboportaal")]),_vm._v("): "),_c('ul',[_c('li',[_vm._v("(om)scholing;")]),_c('li',[_vm._v("(meer) thuiswerken;")]),_c('li',[_vm._v("langere werkdagen (4x9 uur) afgewisseld met meer vrije dagen;")]),_c('li',[_vm._v("flexibele contracten (oproepkrachten voor piekperiodes);")]),_c('li',[_vm._v("verlofdagen (ver)kopen;")]),_c('li',[_vm._v("sparen voor sabbatical.")])])])])])])])]):_vm._e()])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'psychosocial-step-8'}}},[_vm._v("8. Werkruimtes en veiligheid "),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5"},[_c('span',{staticClass:"color-yellow rotate heading-font bold"},[_vm._v("Toelichting")]),_c('div',{staticClass:"p-5"},[_c('p',[_vm._v(" Om een zo hoog mogelijke duurzame inzetbaarheid te realiseren is het, gezien de stijgende leeftijd van zorgverleners en de daaraan gekoppelde extra risico’s op verzuim en uitstroom, steeds belangrijker om werklastbeheersing en leeftijdsbewust vitaliteits- en personeelsbeleid in praktijk te brengen. Oudere zorgverleners brengen wel veel kennis en ervaring met zich mee die waardevol is voor elke organisatie. Het behoud van deze zorgverleners vergt extra aandacht voor belastbaarheid, motivatie en ontwikkeling, om uitval te voorkomen en de inzetbaarheid op peil te houden. Er zijn echter geen specifieke eisen in de Arbowet vastgelegd, anders dan de gewone eisen die dan in versterkte mate gelden voor ouder wordende zorgverleners. ")])])])}]

export { render, staticRenderFns }