<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 2.3 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">2. Uw algemene PSA-beleid</h3>
                        <h4>2a. Heeft uw organisatie een structureel PSA-beleid ingevoerd?</h4>
                        <p>
                            PSA-beleid moet in elk geval worden vastgelegd in een PSA-beleidsplan of plan van aanpak. Dat is wettelijk verplicht. Vervolgens moet het worden ingevoerd.
                        </p>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Of u, zoals verplicht is, het PSA-beleid heeft vastgelegd is zodoende objectief te controleren. Het antwoord op de vraag of u ook daadwerkelijk dit beleid optimaal
                                    heeft
                                    ingevoerd is vrij subjectief, maar het is wel belangrijk om daar bij stil te staan en na te gaan of het voldoende, maar liefst optimaal is. U houdt uzelf zo een
                                    spiegel
                                    voor en ziet welke verbeterpunten er zijn.
                                </p>
                                <p class="mb-0">
                                    Door middel van het doorlopen van de onderstaande vervolgvragen uit deze BeleidsSpiegel PSA krijgt u een beter beeld van de kwaliteit van uw beleid psychosociale
                                    arbeidsbelasting. En dus tegelijkertijd ook van de verbeterpunten.
                                </p>
                            </div>
                        </div>
                        <h5>voor zorgverleners</h5>
                        <ChoiceField id="2.a.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <h5>voor medewerkers van ondersteunde diensten</h5>
                        <ChoiceField id="2.a.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <!-- Question 2.4 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2b. Wordt dit PSA-beleid jaarlijks gecontroleerd/ gemonitord en daar waar nodig bijgesteld?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Ook hier geldt: het plan van aanpak in de vorm van een beleidsplan PSA moet ‘actueel’ zijn. Dat is van groot belang. De Covid-19-pandemie heeft bijvoorbeeld
                                    laten zien dat de situatie snel kan veranderen. Aanpassingen in arbeidsomstandigheden-beleid in algemene zin en PSA-beleid in het bijzonder kunnen zodoende soms
                                    snel noodzakelijk zijn. Aangegeven wordt dat het PSA-beleid minimaal jaarlijks gecontroleerd moet worden en daar waar nodig moet worden bijgesteld.
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.b').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub text-justify">
                                    In de Arbo-catalogus van uw branche kunt u veel informatie vinden over de precieze eisen aan dit beleid. Deze catalogi worden regelmatig geactualiseerd. Hoewel de
                                    Arbo-catalogi van de zorg op hoofdlijnen sterke overeenkomsten vertonen, heeft elke zorgbranche een eigen Arbo-catalogus. De links naar deze Arbo-catalogi zijn te
                                    vinden via <a href="https://www.arboportaal.nl" target="_blank">arboportaal.nl</a>. U treft hier een overzicht aan.<br><br>

                                    <strong>Universitair medische centra (UMC):</strong><br>
                                    <a href="https://www.dokterhoe.nl" target="_blank">https://www.dokterhoe.nl</a> | <a href="https://www.nfu.nl" target="_blank">https://www.nfu.nl</a><br><br>

                                    <strong>Ziekenhuizen, revalidatiecentra en categorale instellingen:</strong><br>
                                    <a href="https://www.betermetarbo.nl" target="_blank">https://www.betermetarbo.nl</a> | <a href="https://www.staz.nl/onderwerpen/veiligezorg/" target="_blank">https://www.staz.nl/onderwerpen/veiligezorg</a><br><br>

                                    <strong>Ambulancezorg:</strong><br>
                                    <a href="https://www.ambulancezorg.nl/themas/arbeidsmarkt-en-werkgeverschap/arbeidsomstandigheden" target="_blank">https://www.ambulancezorg.nl/themas/arbeidsmarkt-en-werkgeverschap/arbeidsomstandigheden</a><br><br>

                                    <strong>Huisartsenzorg:</strong><br>
                                    <a href="https://ssfh.nieuw.dearbocatalogus.nl" target="_blank">https://ssfh.nieuw.dearbocatalogus.nl</a> | <a href="https://www.ssfh.nl/veilig-werken/"
                                                                                                                                                   target="_blank">https://www.ssfh.nl/veilig-werken</a><br><br>

                                    <strong>Verpleging, verzorging en thuiszorg (VVT):</strong><br>
                                    <a href="https://www.arbocatalogusvvt.nl" target="_blank">https://www.arbocatalogusvvt.nl</a><br><br>

                                    <strong>Geestelijke gezondheidszorg (GGZ):</strong><br>
                                    <a href="https://www.gezondenveiligwerken.nl" target="_blank">https://www.gezondenveiligwerken.nl</a><br><br>

                                    <strong>Gehandicaptenzorg:</strong><br>
                                    <a href="https://www.arbocatalogusgehandicaptenzorg.nl" target="_blank">https://www.arbocatalogusgehandicaptenzorg.nl</a> | <a
                                    href="https://www.veiligplusaanpak.nl" target="_blank">https://www.veiligplusaanpak.nl</a><br><br>

                                    <strong>Jeugdzorg:</strong><br>
                                    <a href="https://www.jeugdzorg-werkt.nl/arbocatalogus-jeugdzorg/" target="_blank">https://www.jeugdzorg-werkt.nl/arbocatalogus-jeugdzorg</a> |
                                    <a href="https://www.jeugdzorg-werkt.nl/werkgevers/gezond-en-veilig-werken/agressie/" target="_blank">https://www.jeugdzorg-werkt.nl/werkgevers/gezond-en-veilig-werken/agressie</a><br><br>

                                    <strong>Sociaal werk:</strong><br>
                                    <a href="https://www.sociaalwerk-werkt.nl/arbocatalogus-sociaal-werk/" target="_blank">https://www.sociaalwerk-werkt.nl/arbocatalogus-sociaal-werk</a> |
                                    <a href="https://www.sociaalwerk-werkt.nl/werkgevers/vitaal-en-veilig-werken/agressie/" target="_blank">https://www.sociaalwerk-werkt.nl/werkgevers/vitaal-en-veilig-werken/agressie</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.5 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2c. Worden incidenten op het gebied van psychosociale arbeidsbelasting (agressie, geweld, discriminatie) systematisch geregistreerd?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Om problemen effectief terug te kunnen dringen, moeten incidenten veilig gemeld kunnen worden. Het is belangrijk ze altijd te melden en werknemers te stimuleren ze
                                    te
                                    melden. Dat is alleen al nodig om een goed overzicht te krijgen om verder beleid vorm te geven. Incidenten moeten uiterst serieus genomen worden en vroegsignalering
                                    kan
                                    helpen om ze in de toekomst te voorkomen en de gevolgen ervan te beperken.
                                </p>
                                <p class="mb-0">
                                    Toch is het melden en registreren van incidenten slechts een eerste stap. Om de veiligheid van patiënten, cliënten en medewerkers daadwerkelijk te kunnen vergroten,
                                    is het
                                    van cruciaal belang om vervolgens de oorzaken van de gemelde incidenten grondig te analyseren en aan te pakken
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.c" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.c').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tips</span>
                                <div class="sub">
                                    <ol>
                                        <li>
                                            Werkgevers en werknemers in de zorg, hebben samen <a href="/uploads/STATEMENT.pdf" target="_blank">een statement</a> opgesteld. Hierin is afgesproken dat
                                            elke
                                            organisatie structureel en in de hele organisatie verankerd, integraal beleid moet voeren dat zich zowel richt op preventie en handelen bij
                                            incidenten als op nazorg. Bij incidenten geldt dat dit beleid waar nodig gecombineerd moet worden met een juridisch traject.
                                        </li>
                                        <li>
                                            Bespreek met elkaar (in de teams, op de locatie of binnen de hele organisatie) wat er wel en wat er niet het melden waard is. En maak daar
                                            duidelijke afspraken over.
                                        </li>
                                        <li>
                                            Kost het teveel tijd om altijd alle meldingen te registreren? Houd dan elke maand samen een ‘meldweek’ en leg in die week alles vast. Dat
                                            geeft al een duidelijk overzicht.
                                        </li>
                                    </ol>
                                    <p class="mb-0">
                                        Meer tips vindt u hier: <a href="/uploads/DEF-A6-boekje-altijd-melden-LR.pdf" target="_blank">‘Boekje altijd melden’</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.6 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2d. Heeft uw organisatie één of meerdere vertrouwenspersonen?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Een melding van agressie, geweld, discriminatie of van meer algemene, ongewenste omgangsvormen moet altijd serieus worden genomen. Een vertrouwenspersoon kan
                                    helpen, zeker
                                    als het slachtoffer een drempel ervaart om erover te praten. Een vertrouwenspersoon is een laagdrempelig aanspreekpunt waar iedereen uit de organisatie terecht kan,
                                    onafhankelijk van opleiding of positie in de organisatie. Dit kan een interne of een externe vertrouwenspersoon zijn. Een interne vertrouwenspersoon is een collega
                                    die
                                    (mede) als vertrouwenspersoon is aangesteld. Een externe vertrouwenspersoon is door de werkgever extern ingehuurd om die rol te vervullen. De vertrouwenspersoon
                                    moet door
                                    alle werknemers van de organisatie vertrouwelijk benaderd kunnen worden met klachten of signalen van ongewenst en/of schadelijk gedrag.
                                    Een werknemer moet zelf, zonder bijvoorbeeld eerst een leidinggevende in te hoeven lichten, de vertrouwenspersoon in kunnen schakelen. Datgene wat besproken wordt
                                    valt
                                    onder een geheimhoudingsplicht. Een vertrouwenspersoon kan echter, als dat van toepassing is, ook helpen om het probleem binnen de organisatie aan de orde te
                                    stellen.
                                </p>
                                <p>
                                    De Arbo-wet bevat geen verplichting tot het aanstellen van een vertrouwenspersoon. De werkgever mag een alternatief verzinnen voor de opvang van werknemers die ongewenst gedrag ervaren, maar dat moet wel gelijkwaardig of beter zijn dan de aanstelling van een vertrouwenspersoon. De Inspectie SZW controleert ook op dit punt of het beleid van de werkgever voldoet aan de wet. Meer informatie kunt u bijv. <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">hier</a> vinden in de wegwijzer vertrouwenspersoon.<br>
                                    Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                </p>
                                <p class="mb-0">
                                    Het is lastig te bepalen hoeveel vertrouwenspersonen nodig zijn. Over het algemeen kan worden gesteld dat hoe minder divers het personeelsbestand van de organisatie
                                    is, hoe
                                    minder vertrouwenspersonen men nodig heeft om iedereen die dat nodig heeft te kunnen ondersteunen. Maar daarnaast geldt: hoe meer locaties, hoe meer
                                    vertrouwenspersonen er
                                    nodig zijn. Organisaties met meer dan duizend werknemers en de meer complexe organisaties (verschillende werksoorten, gespreide locaties, complexe zorgvormen)
                                    benoemen vaak
                                    een aantal vertrouwenspersonen. Zorg in elk geval voor vertrouwenspersonen die de verschillende locaties of afdelingen kennen en zorg voor diversiteit in sekse en
                                    culturele
                                    achtergrond.<br>
                                    Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet voldoende',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.d').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.d').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">
                                    Download <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">hier</a> de Wegwijzer
                                    Vertrouwenspersoon Ongewenste Omgangsvormen.<br>
                                    Deze bevat praktische informatie over wanneer een vertrouwenspersoon nodig is, informatie voor vertrouwenspersonen of zij die dat willen worden en informatie voor
                                    werkgevers die vertrouwenspersonen willen aanstellen en een kader daarvoor zoeken.
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- Question 2.7 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2e. Beschikt uw organisatie over een klachtenprocedure voor problemen op het gebied van PSA?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Als de eerder genoemde, meer informele wegen niet tot een gewenst resultaat leiden of de vorm en aard van het pestgedrag/seksuele intimidatie/discriminatie te
                                    ernstig is,
                                    kan een formeel traject noodzakelijk zijn en helpend zijn. Het doel van het formele traject is dat zorgvuldig onderzoek via hoor en wederhoor plaatsvindt op basis
                                    waarvan
                                    zorgvuldige beslissingen genomen kunnen worden. Ook moet een uitspraak kunnen worden gedaan of een klacht gegrond is of niet. Een leidinggevende kan deze taak niet
                                    vervullen, omdat die zelf partij kan zijn in bijvoorbeeld een conflict. De vertrouwenspersoon is partijdig en staat aan de kant van de melder/medewerker en kan dus niet bemiddelen of een klacht onderzoeken. Als dat gewenst kan er een onpartijdige bemiddelaar ingeschakeld worden. De vertrouwenspersoon heeft geheimhoudingsplicht en handelt alleen met toestemming van de melder/medewerker.
                                </p>
                                <p>
                                    Een werkgever moet kunnen aantonen allereerst hoe een medewerker een formele klacht kan indienen en ten tweede hoe medewerkers hiervan op de hoogte zijn gesteld.
                                    Ten derde
                                    moet duidelijk worden hoe ervoor gezorgd wordt dat de ingediende klacht zorgvuldig, onpartijdig en deskundig wordt onderzocht.
                                </p>
                                <p class="mb-0">
                                    De Arbowet is een kaderwet en bevat geen directe verplichting voor een klachtenprocedure. Het beleid moet vorm krijgen ‘volgens de stand van de wetenschap en de
                                    professionele dienstverlening’. Een toelichting op de wet vermeldt wel het bestaan van een klachtenprocedure. De werkgever mag wel een alternatief verzinnen, maar
                                    dat moet
                                    dan wel aantoonbaar gelijkwaardig of beter zijn dan een klachtenprocedure of klachtenregeling. Er moet in elk geval omschreven worden hoe het formele
                                    klachtentraject
                                    georganiseerd is en uitgevoerd wordt. Een aanpak die hetzelfde beschermingsniveau biedt, mag echter ook. De Inspectie SZW controleert en toetst of het beleid van de
                                    werkgever voldoet aan de wet.<br>
                                    Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.e" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet volledig',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.e').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.e').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">
                                    De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in detail
                                    uitgewerkt waar het beleid op dit punt van klachtenregeling en -procedures aan moet voldoen. U kunt <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf"
                                                                                                                                           target="_blank">dit document hier downloaden</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2f. Beschikt uw organisatie over een klachtencommissie voor problemen op het gebied van PSA?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Sommige situaties op de werkvloer zijn zo ernstig of escaleren dusdanig dat het noodzakelijk is om een, officiële, klacht in te dienen bij een klachtencommissie.
                                    Die moet
                                    er dan natuurlijk wel zijn. Een klachtencommissie is een onderdeel van het wettelijke verplichte PSA-beleid. Volgens het Ministerie van Sociale Zaken en
                                    Werkgelegenheid
                                    dienen klachten over ongewenst gedrag onderzocht te kunnen worden door een onafhankelijke en ter zake kundige klachtencommissie. Deze verplichting vloeit volgens
                                    het
                                    Ministerie voort uit artikel 2.15 lid 1 van de Arbobesluit.
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.f" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet volledig',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.f').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.f').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">
                                    De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in detail
                                    uitgewerkt waar het beleid op dit punt aan moet voldoen. U kunt <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf" target="_blank">dit
                                                                                                                                                                       document
                                                                                                                                                                       hier
                                                                                                                                                                       downloaden</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h4>2g. Beschikt uw organisatie over een ‘Protocol ongewenste omgangsvormen’ op het gebied van PSA?</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Een Protocol Ongewenste Omgangsvormen is mede gebaseerd op de wet- en regelgeving over ongewenste omgangsvormen: de Arbo-wet en de wetten gelijke behandeling. De
                                    Arbo-wet
                                    verplicht de werkgever tot een beleid dat gericht is op het voorkomen en beperken van psychosociale overbelasting door het werk. In een protocol ongewenste
                                    omgangsvormen
                                    beschrijft u hoe de organisatie omgaat met ongewenst gedrag. Op die manier krijgen ongewenste omgangsvormen minder kans.
                                </p>
                            </div>
                        </div>
                        <ChoiceField id="2.g" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet volledig',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="this.$store.getters['psychosocialStore/getQuestionById']('2.g').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.g').value !== null">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <div class="sub">
                                    <ol>
                                        <li>
                                            De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in
                                            detail
                                            uitgewerkt waar het beleid op dit punt aan moet voldoen. U kunt <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf" target="_blank">dit document hier
                                                                                                                                                                               downloaden</a>.
                                        </li>
                                        <li>
                                            Er zijn meerdere voorbeeldprotocollen op internet te vinden. Zie bijvoorbeeld <a
                                            href="/uploads/voorbeeld_protocol-ongewenst-gedrag_voorbeeld_vob_arboned.pdf"
                                            target="_blank">hier</a>.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-3'}" class="btn btn-grad mt-3">3. Het veiligheidsklimaat in uw organisatie<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep2',
    components: { ChoiceField, Progress }
}
</script>
