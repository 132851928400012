<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 5.10 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">5. Vertaling Praktijkrichtlijnen naar niet-cliënt gebonden handelingen.</h3>
                        <p class="mb-5">Met 'niet-cliënt gebonden handelingen' bedoelen we bijvoorbeeld het tillen van objecten, het rijden met karren, het reiken in een magazijn,
                                        etc. Deze handelingen worden in de praktijk vooral door medewerkers van de ondersteunende diensten uitgevoerd.</p>
                        <h4>10a. Wordt er van alle mogelijk fysiek belastende, niet-cliënt gebonden handelingen vooraf een zorgvuldige inschatting van de risico's gemaakt?</h4>
                        <p class="mb-5">Om ergonomisch te kunnen werken is het nodig om van elke mogelijk fysiek belastende, niet-cliëntgebonden handeling een zorgvuldige
                                        inschatting te maken van de risico’s. Leidend zijn daarbij de Praktijkrichtlijnen Fysieke Belasting die zijn geformuleerd voor de 15
                                        hoofdbronnen van fysieke belasting voor medewerkers van ondersteunende diensten.</p>
                        <ChoiceField id="10.a" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('10.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">De Praktijkrichtlijnen Fysieke Belasting voor medewerkers van ondersteunende diensten zijn te vinden in uw
                                                            Arbocatalogus.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>10b. Wordt op basis van die inschatting gekozen voor een gezonde werkmethode?</h4>
                        <p class="mb-5">Denk hierbij bijvoorbeeld aan afspraken over de verwerking van het vuilnis, waszakken, het gebruik van hulpmiddelen om hoog te kunnen werken
                                        of het tillen van meubilair bij een interne verhuizing.</p>
                        <ChoiceField id="10.b" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('10.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op GoedGebruik www.goedgebruik.nl voor films over werken conform de Praktijkrichtlijnen Fysieke Belasting voor
                                                            medewerkers van ondersteunende diensten.</p>
                                        <a href="https://www.goedgebruik.nl" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>10c. Worden deze afspraken doorgaans nageleefd?</h4>
                        <p class="mb-5">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te betrekken bij het proces, maar daarin nóg een
                                        stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.</p>
                        <ChoiceField id="10.c" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('10.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te
                                                            betrekken
                                                            bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen
                                                            op te
                                                            lossen. De Voilà-methode biedt hiervoor een goede structuur. Kijk op de website van LOCOmotion voor
                                                            achtergrondinformatie,
                                                            films, cartoons, posters etc.</p>
                                        <a href="https://www.locomotion.nl/voila-methode" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>10d. Wordt op naleving van die afspraken toegezien?</h4>
                        <p class="mb-5">Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen de reguliere werkprocessen, maar ook via de ErgoCoaches, de
                                        Risico(Rug)Radar of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op zichzelf, maar levert
                                        het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee meer een vorm van
                                        monitoring geworden en minder een vorm van controle.</p>
                        <ChoiceField id="10.d" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('10.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'.</p>
                                        <a href="https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-6'}" class="btn btn-grad mt-3">6. Bijzondere situaties<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step5',
    components: { ChoiceField, Progress }
}
</script>
