<template>
    <div>
        <section class="p-0">
            <div class="row">
                <div class="col-md-12 image-bg page-title">
                    <div class="background-image overlay-gradient-left" style="background-image:url(/img/faq.jpg);"></div>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-7 col-12 order-2 order-md-1">
                                <h1 class="large" data-aos="fade-up" data-aos-delay="100">Veelgestelde vragen</h1>
                                <p data-aos="fade-up" data-aos-delay="300">Hier vindt u antwoorden op de meest gestelde vragen. Staat uw vraag er niet tussen? Neem dan gerust
                                                                           contact op via onderstaande button.</p>
                                <div class="btn-container" data-aos="fade-up" data-aos-delay="500">
                                    <a href="mailto:nico@knibbe.info" class="btn btn-grad">Contact opnemen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div id="accordion">
                            <div class="accordion-item mb-3" v-bind:key="faq.id" v-for="(faq, index) in faqs">
                                <div class="accordion-header" data-toggle="collapse" :data-target="'#collapse' + index+1" aria-expanded="true"
                                     :aria-controls="'collapse' + index+1">
                                    <span class="accordion-title">
                                        {{ faq.question }}
                                    </span>
                                </div>
                                <div :id="'collapse' + index+1" class="collapse">
                                    <div class="accordion-body">
                                        {{ faq.answer }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Partner />
    </div>
</template>

<script>
import Partner from "../../defaults/Partners";

export default {
    name:       'Faq',
    components: { Partner },
    data() {
        return {
            'faqs': {}
        }
    },
    created() {
        this.faqs = require('../../../json/faq.json');
    }
}
</script>