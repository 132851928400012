<template>
    <div>
        <footer class="pt-3 pb-3 bg-white">
            <div class="container">
                <div class="row v-align-children">
                    <div class="col-lg-12">
                        <span class="sub-sub">&copy; {{ year }}  <a href="https://locomotion.nl" target="_blank">LOCOmotion</a> | Contact: <a href="mailto:nico@knibbe.info">nico@knibbe.info</a> | Opdrachtgever: <a href="https://regioplus.nl/" target="_blank">RegioPlus</a> | <router-link to="/copyright">Copyright &amp; disclaimer</router-link> | <router-link
                            to="/privacyverklaring">Privacyverklaring</router-link> | Realisatie: <a
                            href="https://benedenboven.nl" target="_blank">BenedenBoven</a></span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name:     'Footer',
    computed: {
        year: function() {
            return (new Date()).getFullYear();
        }
    }
}
</script>
