var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("5. Vertaling Praktijkrichtlijnen naar niet-cliënt gebonden handelingen.")]),_c('p',{staticClass:"mb-5"},[_vm._v("Met 'niet-cliënt gebonden handelingen' bedoelen we bijvoorbeeld het tillen van objecten, het rijden met karren, het reiken in een magazijn, etc. Deze handelingen worden in de praktijk vooral door medewerkers van de ondersteunende diensten uitgevoerd.")]),_c('h4',[_vm._v("10a. Wordt er van alle mogelijk fysiek belastende, niet-cliënt gebonden handelingen vooraf een zorgvuldige inschatting van de risico's gemaakt?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om ergonomisch te kunnen werken is het nodig om van elke mogelijk fysiek belastende, niet-cliëntgebonden handeling een zorgvuldige inschatting te maken van de risico’s. Leidend zijn daarbij de Praktijkrichtlijnen Fysieke Belasting die zijn geformuleerd voor de 15 hoofdbronnen van fysieke belasting voor medewerkers van ondersteunende diensten.")]),_c('ChoiceField',{attrs:{"id":"10.a","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('10.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.a').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("De Praktijkrichtlijnen Fysieke Belasting voor medewerkers van ondersteunende diensten zijn te vinden in uw Arbocatalogus.")])])])]):_vm._e()]),_c('h4',[_vm._v("10b. Wordt op basis van die inschatting gekozen voor een gezonde werkmethode?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Denk hierbij bijvoorbeeld aan afspraken over de verwerking van het vuilnis, waszakken, het gebruik van hulpmiddelen om hoog te kunnen werken of het tillen van meubilair bij een interne verhuizing.")]),_c('ChoiceField',{attrs:{"id":"10.b","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('10.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.b').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Kijk op GoedGebruik www.goedgebruik.nl voor films over werken conform de Praktijkrichtlijnen Fysieke Belasting voor medewerkers van ondersteunende diensten.")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"https://www.goedgebruik.nl","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()]),_c('h4',[_vm._v("10c. Worden deze afspraken doorgaans nageleefd?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te betrekken bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.")]),_c('ChoiceField',{attrs:{"id":"10.c","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('10.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.c').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te betrekken bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen. De Voilà-methode biedt hiervoor een goede structuur. Kijk op de website van LOCOmotion voor achtergrondinformatie, films, cartoons, posters etc.")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"https://www.locomotion.nl/voila-methode","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()]),_c('h4',[_vm._v("10d. Wordt op naleving van die afspraken toegezien?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen de reguliere werkprocessen, maar ook via de ErgoCoaches, de Risico(Rug)Radar of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op zichzelf, maar levert het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee meer een vorm van monitoring geworden en minder een vorm van controle.")]),_c('ChoiceField',{attrs:{"id":"10.d","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('10.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.d').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'.")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'step-6'}}},[_vm._v("6. Bijzondere situaties"),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = []

export { render, staticRenderFns }