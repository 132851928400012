<template>
    <div class="progress">
        <div class="progress-bar" role="progressbar" :style="'width:' + percentageDone + '%;'" :aria-valuenow="percentageDone" aria-valuemin="0" aria-valuemax="100">
            {{ percentageDone }}%
        </div>
    </div>
</template>

<script>
export default {
    name:     'Progress',
    computed: {
        percentageDone() {
            const store = this.$route.path.startsWith('/fysieke-belasting') ? 'physicalStore' : 'psychosocialStore';

            return this.$store.state[store].percentageDone;
        }
    }
}
</script>