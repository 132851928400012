<template>
    <div>
        <div id="overlay-export">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h4>Bezig met het genereren van de PDF. Dit kan even duren...</h4>
        </div>
        <div id="exportable">
            <section class="bg-secondary pt-12 pb-5 first-section">
                <div class="container">
                    <div class="row justify-content-center" data-aos="fade-up">
                        <div class="col-md-12 text-center mb-3">
                            <img src="/img/logo-pa.svg" class="logo mb-5" v-if="this.exporting">
                            <h3 class="color-primary">Resultaten</h3>
                            <p>Op basis van de ingevoerde gegevens is er een score berekend per 'dimensie'.<br><strong>Let op:</strong> Deze website onthoudt uw
                               gegevens niet, dus bewaar de pdf zorgvuldig.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-right">
                            <ul class="no-bullets">
                                <li>Naam</li>
                                <li>Organisatie</li>
                                <li>Datum</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul class="no-bullets">
                                <li class="bold heading-font">{{ name }}</li>
                                <li class="bold heading-font">{{ organizationName }}</li>
                                <li class="bold heading-font">{{ date }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 mx-auto">
                            <PsychoSocialResultChart :key="resultsChartKey" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="d-block">Toelichting per dimensie</h4>
                            <label><input type="checkbox" v-model="showExplanations"> Toon toelichtingen</label>
                            <label class="ml-3"><input type="checkbox" v-model="showTips"> Toon tips</label>
                            <div id="accordion">
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                <span class="accordion-title">
                                    1. Uw huidige situatie: De RI&amp;E als startpunt
                                </span>
                                    </div>
                                    <div id="collapse1" class="collapse show">
                                        <div class="accordion-body">
                                            <p>Komt psychosociale arbeidsbelasting als een knelpunt naar voren uit uw RI&E?</p>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Een RI&E signaleert slechts in globale zin Arbo-risico’s, zoals psychosociale arbeidsbelasting. Als dat zo blijkt te zijn, is het relevant om uw beleid
                                                            psychosociale arbeidsbelasting nader onder de loep te nemen. U gaat bijvoorbeeld dan met deze BeleidsSpiegel PSA meer de diepte in. Maar ook als dat niet zo is,
                                                            kan het relevant zijn om deze BeleidsSpiegel PSA verder in te vullen om wellicht nadere invulling te geven aan uw beleid.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">1a. voor zorgverleners</p>
                                                <p v-html="getAnswer('1.a') + getScore('1.a')"></p>

                                                <p class="mb-0 bold">1b. voor medewerkers van ondersteunde diensten</p>
                                                <p v-html="getAnswer('1.b') + getScore('1.b')"></p>

                                                <p class="mb-0 bold">1c. voor overige medewerkers</p>
                                                <p v-html="getAnswer('1.c') + getScore('1.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && ((this.$store.getters['psychosocialStore/getQuestionById']('1.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.a').value !== null) ||
                                                     this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('1.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.b').value !== null) ||
                                                     this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('1.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.c').value !== null))">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Er zijn geen wettelijke eisen gesteld aan de frequentie waarmee een RI&E moet worden uitgevoerd. De eis is dat de RI&E ‘actueel’ moet zijn. De RI&E kan eventueel
                                                                ook op onderdelen (bijvoorbeeld alleen bepaalde afdelingen of werkplekken) geactualiseerd worden.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                <span class="accordion-title">
                                    2. Uw algemene PSA-beleid
                                </span>
                                    </div>
                                    <div id="collapse2" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Of u, zoals verplicht is, het PSA-beleid heeft vastgelegd is zodoende objectief te controleren. Het antwoord op de vraag of u ook daadwerkelijk dit beleid optimaal
                                                            heeft
                                                            ingevoerd is vrij subjectief, maar het is wel belangrijk om daar bij stil te staan en na te gaan of het voldoende, maar liefst optimaal is. U houdt uzelf zo een
                                                            spiegel
                                                            voor en ziet welke verbeterpunten er zijn.
                                                        </p>
                                                        <p class="mb-0">
                                                            Door middel van het doorlopen van de onderstaande vervolgvragen uit deze BeleidsSpiegel PSA krijgt u een beter beeld van de kwaliteit van uw beleid psychosociale
                                                            arbeidsbelasting. En dus tegelijkertijd ook van de verbeterpunten.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p>Heeft uw organisatie een structureel PSA-beleid ingevoerd?</p>
                                                <p class="mb-0 bold">2.a.a Voor zorgverleners</p>
                                                <p v-html="getAnswer('2.a.a') + getScore('2.a.a')"></p>

                                                <p class="mb-0 bold">2.a.b voor medewerkers van ondersteunde diensten</p>
                                                <p v-html="getAnswer('2.a.b') + getScore('2.a.b')"></p>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Ook hier geldt: het plan van aanpak in de vorm van een beleidsplan PSA moet ‘actueel’ zijn. Dat is van groot belang. De Covid-19-pandemie heeft bijvoorbeeld
                                                            laten zien dat de situatie snel kan veranderen. Aanpassingen in arbeidsomstandigheden-beleid in algemene zin en PSA-beleid in het bijzonder kunnen zodoende soms
                                                            snel noodzakelijk zijn. Aangegeven wordt dat het PSA-beleid minimaal jaarlijks gecontroleerd moet worden en daar waar nodig moet worden bijgesteld.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">2.b Wordt dit PSA-beleid jaarlijks gecontroleerd/ gemonitord en daar waar nodig bijgesteld?</p>
                                                <p v-html="getAnswer('2.b') + getScore('2.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub text-justify">
                                                                In de Arbo-catalogus van uw branche kunt u veel informatie vinden over de precieze eisen aan dit beleid. Deze catalogi worden regelmatig geactualiseerd. Hoewel de
                                                                Arbo-catalogi van de zorg op hoofdlijnen sterke overeenkomsten vertonen, heeft elke zorgbranche een eigen Arbo-catalogus. De links naar deze Arbo-catalogi zijn te
                                                                vinden via <a href="https://www.arboportaal.nl" target="_blank">arboportaal.nl</a>. U treft hier een overzicht aan.<br><br>

                                                                <strong>Universitair medische centra (UMC):</strong><br>
                                                                <a href="https://www.dokterhoe.nl" target="_blank">https://www.dokterhoe.nl</a> | <a href="https://www.nfu.nl" target="_blank">https://www.nfu.nl</a><br><br>

                                                                <strong>Ziekenhuizen, revalidatiecentra en categorale instellingen:</strong><br>
                                                                <a href="https://www.betermetarbo.nl" target="_blank">https://www.betermetarbo.nl</a> | <a href="https://www.staz.nl/onderwerpen/veiligezorg/" target="_blank">https://www.staz.nl/onderwerpen/veiligezorg</a><br><br>

                                                                <strong>Ambulancezorg:</strong><br>
                                                                <a href="https://www.ambulancezorg.nl/themas/arbeidsmarkt-en-werkgeverschap/arbeidsomstandigheden" target="_blank">https://www.ambulancezorg.nl/themas/arbeidsmarkt-en-werkgeverschap/arbeidsomstandigheden</a><br><br>

                                                                <strong>Huisartsenzorg:</strong><br>
                                                                <a href="https://ssfh.nieuw.dearbocatalogus.nl" target="_blank">https://ssfh.nieuw.dearbocatalogus.nl</a> | <a href="https://www.ssfh.nl/veilig-werken/" target="_blank">https://www.ssfh.nl/veilig-werken</a><br><br>

                                                                <strong>Verpleging, verzorging en thuiszorg (VVT):</strong><br>
                                                                <a href="https://www.arbocatalogusvvt.nl" target="_blank">https://www.arbocatalogusvvt.nl</a><br><br>

                                                                <strong>Geestelijke gezondheidszorg (GGZ):</strong><br>
                                                                <a href="https://www.gezondenveiligwerken.nl" target="_blank">https://www.gezondenveiligwerken.nl</a><br><br>

                                                                <strong>Gehandicaptenzorg:</strong><br>
                                                                <a href="https://www.arbocatalogusgehandicaptenzorg.nl" target="_blank">https://www.arbocatalogusgehandicaptenzorg.nl</a> | <a href="https://www.veiligplusaanpak.nl" target="_blank">https://www.veiligplusaanpak.nl</a><br><br>

                                                                <strong>Jeugdzorg:</strong><br>
                                                                <a href="https://www.jeugdzorg-werkt.nl/arbocatalogus-jeugdzorg/" target="_blank">https://www.jeugdzorg-werkt.nl/arbocatalogus-jeugdzorg</a> |
                                                                <a href="https://www.jeugdzorg-werkt.nl/werkgevers/gezond-en-veilig-werken/agressie/" target="_blank">https://www.jeugdzorg-werkt.nl/werkgevers/gezond-en-veilig-werken/agressie</a><br><br>

                                                                <strong>Sociaal werk:</strong><br>
                                                                <a href="https://www.sociaalwerk-werkt.nl/arbocatalogus-sociaal-werk/" target="_blank">https://www.sociaalwerk-werkt.nl/arbocatalogus-sociaal-werk</a> |
                                                                <a href="https://www.sociaalwerk-werkt.nl/werkgevers/vitaal-en-veilig-werken/agressie/" target="_blank">https://www.sociaalwerk-werkt.nl/werkgevers/vitaal-en-veilig-werken/agressie</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <p class="bold mb-0">2.c Worden incidenten op het gebied van psychosociale arbeidsbelasting (agressie, geweld, discriminatie) systematisch geregistreerd?</p>
                                                <p v-html="getAnswer('2.c') + getScore('2.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tips</span>
                                                            <div class="sub">
                                                                <ol>
                                                                    <li>
                                                                        Werkgevers en werknemers in de zorg, hebben samen <a href="/uploads/STATEMENT.pdf" target="_blank">een statement</a> opgesteld. Hierin is afgesproken dat
                                                                        elke
                                                                        organisatie structureel en in de hele organisatie verankerd, integraal beleid moet voeren dat zich zowel richt op preventie en handelen bij
                                                                        incidenten als op nazorg. Bij incidenten geldt dat dit beleid waar nodig gecombineerd moet worden met een juridisch traject.
                                                                    </li>
                                                                    <li>
                                                                        Bespreek met elkaar (in de teams, op de locatie of binnen de hele organisatie) wat er wel en wat er niet het melden waard is. En maak daar
                                                                        duidelijke afspraken over.
                                                                    </li>
                                                                    <li>
                                                                        Kost het teveel tijd om altijd alle meldingen te registreren? Houd dan elke maand samen een ‘meldweek’ en leg in die week alles vast. Dat
                                                                        geeft al een duidelijk overzicht.
                                                                    </li>
                                                                </ol>
                                                                <p class="mb-0">
                                                                    Meer tips vindt u hier: <a href="/uploads/DEF-A6-boekje-altijd-melden-LR.pdf" target="_blank">‘Boekje altijd melden’</a>.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Een melding van agressie, geweld, discriminatie of van meer algemene, ongewenste omgangsvormen moet altijd serieus worden genomen. Een vertrouwenspersoon kan
                                                            helpen, zeker
                                                            als het slachtoffer een drempel ervaart om erover te praten. Een vertrouwenspersoon is een laagdrempelig aanspreekpunt waar iedereen uit de organisatie terecht kan,
                                                            onafhankelijk van opleiding of positie in de organisatie. Dit kan een interne of een externe vertrouwenspersoon zijn. Een interne vertrouwenspersoon is een collega
                                                            die
                                                            (mede) als vertrouwenspersoon is aangesteld. Een externe vertrouwenspersoon is door de werkgever extern ingehuurd om die rol te vervullen. De vertrouwenspersoon
                                                            moet door
                                                            alle werknemers van de organisatie vertrouwelijk benaderd kunnen worden met klachten of signalen van ongewenst en/of schadelijk gedrag.
                                                            Een werknemer moet zelf, zonder bijvoorbeeld eerst een leidinggevende in te hoeven lichten, de vertrouwenspersoon in kunnen schakelen. Datgene wat besproken wordt
                                                            valt
                                                            onder een geheimhoudingsplicht. Een vertrouwenspersoon kan echter, als dat van toepassing is, ook helpen om het probleem binnen de organisatie aan de orde te
                                                            stellen.
                                                        </p>
                                                        <p>
                                                            De Arbo-wet bevat geen verplichting tot het aanstellen van een vertrouwenspersoon. De werkgever mag een alternatief verzinnen voor de opvang van werknemers die ongewenst gedrag ervaren, maar dat moet wel gelijkwaardig of beter zijn dan de
                                                            aanstelling van een vertrouwenspersoon. De Inspectie SZW controleert ook op dit punt of het beleid van de werkgever voldoet aan de wet. Meer informatie kunt u bijv. <a
                                                            href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">hier</a> vinden in de wegwijzer vertrouwenspersoon.<br>
                                                            Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                                        </p>
                                                        <p class="mb-0">
                                                            Het is lastig te bepalen hoeveel vertrouwenspersonen nodig zijn. Over het algemeen kan worden gesteld dat hoe minder divers het personeelsbestand van de organisatie
                                                            is, hoe
                                                            minder vertrouwenspersonen men nodig heeft om iedereen die dat nodig heeft te kunnen ondersteunen. Maar daarnaast geldt: hoe meer locaties, hoe meer
                                                            vertrouwenspersonen er
                                                            nodig zijn. Organisaties met meer dan duizend werknemers en de meer complexe organisaties (verschillende werksoorten, gespreide locaties, complexe zorgvormen)
                                                            benoemen vaak
                                                            een aantal vertrouwenspersonen. Zorg in elk geval voor vertrouwenspersonen die de verschillende locaties of afdelingen kennen en zorg voor diversiteit in sekse en
                                                            culturele
                                                            achtergrond.<br>
                                                            Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">2.d Heeft uw organisatie één of meerdere vertrouwenspersonen?</p>
                                                <p v-html="getAnswer('2.d') + getScore('2.d')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.d').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Download <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">hier</a> de Wegwijzer
                                                                Vertrouwenspersoon Ongewenste Omgangsvormen.<br>
                                                                Deze bevat praktische informatie over wanneer een vertrouwenspersoon nodig is, informatie voor vertrouwenspersonen of zij die dat willen worden en informatie voor
                                                                werkgevers die vertrouwenspersonen willen aanstellen en een kader daarvoor zoeken.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Als de eerder genoemde, meer informele wegen niet tot een gewenst resultaat leiden of de vorm en aard van het pestgedrag/seksuele intimidatie/discriminatie te
                                                            ernstig is,
                                                            kan een formeel traject noodzakelijk zijn en helpend zijn. Het doel van het formele traject is dat zorgvuldig onderzoek via hoor en wederhoor plaatsvindt op basis
                                                            waarvan
                                                            zorgvuldige beslissingen genomen kunnen worden. Ook moet een uitspraak kunnen worden gedaan of een klacht gegrond is of niet. Een leidinggevende kan deze taak niet
                                                            vervullen, omdat die zelf partij kan zijn in bijvoorbeeld een conflict. De vertrouwenspersoon is partijdig en staat aan de kant van de melder/medewerker en kan dus niet bemiddelen of een klacht onderzoeken. Als dat gewenst kan er een
                                                            onpartijdige
                                                            bemiddelaar ingeschakeld worden. De vertrouwenspersoon heeft geheimhoudingsplicht en handelt alleen met toestemming van de melder/medewerker.
                                                        </p>
                                                        <p>
                                                            Een werkgever moet kunnen aantonen allereerst hoe een medewerker een formele klacht kan indienen en ten tweede hoe medewerkers hiervan op de hoogte zijn gesteld.
                                                            Ten derde
                                                            moet duidelijk worden hoe ervoor gezorgd wordt dat de ingediende klacht zorgvuldig, onpartijdig en deskundig wordt onderzocht.
                                                        </p>
                                                        <p class="mb-0">
                                                            De Arbowet is een kaderwet en bevat geen directe verplichting voor een klachtenprocedure. Het beleid moet vorm krijgen ‘volgens de stand van de wetenschap en de
                                                            professionele dienstverlening’. Een toelichting op de wet vermeldt wel het bestaan van een klachtenprocedure. De werkgever mag wel een alternatief verzinnen, maar
                                                            dat moet
                                                            dan wel aantoonbaar gelijkwaardig of beter zijn dan een klachtenprocedure of klachtenregeling. Er moet in elk geval omschreven worden hoe het formele
                                                            klachtentraject
                                                            georganiseerd is en uitgevoerd wordt. Een aanpak die hetzelfde beschermingsniveau biedt, mag echter ook. De Inspectie SZW controleert en toetst of het beleid van de
                                                            werkgever voldoet aan de wet.<br>
                                                            Bron: <a href="https://www.arbocatalogusvvt.nl/wp-content/uploads/2020/04/wegwijzer-vertrouwenspersoon.pdf" target="_blank">Wegwijzer vertrouwenspersoon</a>
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">2.e Beschikt uw organisatie over een klachtenprocedure voor problemen op het gebied van PSA?</p>
                                                <p v-html="getAnswer('2.e') + getScore('2.e')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.e').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.e').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in detail
                                                                uitgewerkt waar het beleid op dit punt van klachtenregeling en -procedures aan moet voldoen. U kunt <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf"
                                                                                                                                                                       target="_blank">dit document hier downloaden</a>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Sommige situaties op de werkvloer zijn zo ernstig of escaleren dusdanig dat het noodzakelijk is om een, officiële, klacht in te dienen bij een klachtencommissie.
                                                            Die moet
                                                            er dan natuurlijk wel zijn. Een klachtencommissie is een onderdeel van het wettelijke verplichte PSA-beleid. Volgens het Ministerie van Sociale Zaken en
                                                            Werkgelegenheid
                                                            dienen klachten over ongewenst gedrag onderzocht te kunnen worden door een onafhankelijke en ter zake kundige klachtencommissie. Deze verplichting vloeit volgens
                                                            het
                                                            Ministerie voort uit artikel 2.15 lid 1 van de Arbobesluit.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">2.f Beschikt uw organisatie over een klachtencommissie voor problemen op het gebied van PSA?</p>
                                                <p v-html="getAnswer('2.f') + getScore('2.f')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.f').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.f').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in detail
                                                                uitgewerkt waar het beleid op dit punt aan moet voldoen. U kunt
                                                                <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf" target="_blank">
                                                                    dit document hier downloaden
                                                                </a>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Een Protocol Ongewenste Omgangsvormen is mede gebaseerd op de wet- en regelgeving over ongewenste omgangsvormen: de Arbo-wet en de wetten gelijke behandeling. De
                                                            Arbo-wet
                                                            verplicht de werkgever tot een beleid dat gericht is op het voorkomen en beperken van psychosociale overbelasting door het werk. In een protocol ongewenste
                                                            omgangsvormen
                                                            beschrijft u hoe de organisatie omgaat met ongewenst gedrag. Op die manier krijgen ongewenste omgangsvormen minder kans.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="bold mb-0">2.g Beschikt uw organisatie over een ‘Protocol ongewenste omgangsvormen’ op het gebied van PSA?</p>
                                                <p v-html="getAnswer('2.g') + getScore('2.g')"></p>
                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('2.g').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('2.g').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub">
                                                                <ol>
                                                                    <li>
                                                                        De Inspectie SZW heeft nog niet zo lang geleden (najaar 2020) de ‘BasisInspectieModule (BIM) intern ongewenst gedrag’ gepubliceerd. Daarin staat tot in
                                                                        detail
                                                                        uitgewerkt waar het beleid op dit punt aan moet voldoen. U kunt <a href="/uploads/BIM+Intern+ongewenst+gedrag.pdf" target="_blank">dit document hier
                                                                                                                                                                                                           downloaden</a>.
                                                                    </li>
                                                                    <li>
                                                                        Er zijn meerdere voorbeeldprotocollen op internet te vinden. Zie bijvoorbeeld <a
                                                                        href="/uploads/voorbeeld_protocol-ongewenst-gedrag_voorbeeld_vob_arboned.pdf"
                                                                        target="_blank">hier</a>.
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                    <span class="accordion-title">
                                        3. Het veiligheidsklimaat in uw organisatie
                                    </span>
                                    </div>
                                    <div id="collapse3" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Bronkhorst promoveerde op onderzoek naar de kenmerken van een gezond en veilig klimaat in de zorg en het behoud van personeel. Het bleek dat zorgverleners zich
                                                            lichamelijk
                                                            en geestelijk beter voelen in organisaties die beter scoren op een gezond en veilig klimaat. Deze zorgorganisaties hebben een lager ziekteverzuim en hun medewerkers
                                                            gebruiken zelf minder zorg. De verbetering van het organisatieklimaat blijkt een belangrijk aangrijpingspunt om gezonder en veiliger te werken in de zorg.
                                                            Investeren in een
                                                            dergelijk organisatieklimaat zal in de nabije toekomst steeds belangrijker worden om ook op de lange termijn in ons land de zorg te kunnen leveren die nodig is.
                                                            Het proefschrift kunt u <a href="/uploads/Proefschrift-Babette-Bronkhorst-Healthy-Workplaces-in-Health-Care.pdf" target="_blank">hier downloaden</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p>
                                                    Geef nu uzelf een rapportcijfer voor elk van de stellingen.
                                                </p>
                                                <p class="bold mb-0">
                                                    A. Hoger management legt prioriteit bij gezond en veilig werken:
                                                </p>
                                                <p>{{ getValueAnswer('3.a') }}</p>

                                                <p class="bold mb-0">
                                                    B. Direct leidinggevenden zijn betrokken bij PSA beleid:
                                                </p>
                                                <p>{{ getValueAnswer('3.b') }}</p>

                                                <p class="bold mb-0">
                                                    C. De groepsnorm bij medewerkers over PSA beleid is positief:
                                                </p>
                                                <p>{{ getValueAnswer('3.c') }}</p>

                                                <p class="bold mb-0">
                                                    D. De communicatie over ons PSA beleid is goed:
                                                </p>
                                                <p>{{ getValueAnswer('3.d') }}</p>

                                                <p class="bold mb-0">
                                                    E. Werknemers zijn betrokken bij PSA beleid:
                                                </p>
                                                <p>{{ getValueAnswer('3.e') }}</p>

                                                <div class="hint row justify-content-center" v-if="this.showTips">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Gebruik deze scores om speerpunten in uw plan van aanpak op te stellen om zo effectief mogelijk uw beleid te voeren.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                <span class="accordion-title">
                                    4. Psychosociale arbeidsbelasting en de subthema's
                                </span>
                                    </div>
                                    <div id="collapse4" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Discriminatie op de werkvloer komt op allerlei manieren voor. Bijvoorbeeld al bij de werving van werknemers kunnen naam of pasfoto een grote rol spelen.
                                                            Discriminatie
                                                            kan bewust of onbewust zijn, maar is in alle gevallen ongeoorloofd.
                                                            <a href="https://www.nursing.nl/blog/gastblog-zoe-stop-racisme-in-de-zorg/" target="_blank">Lees hier</a>
                                                            bijvoorbeeld de gastblog van zorgverlener Zoë in Nursing. Zorgverleners èn ook zorgvragers zouden zich nooit hoeven af te vragen of zij anders behandeld worden,
                                                            omwille van hun huidskleur, hun achtergrond of achternaam. Als een zorgvrager zogenaamde grapjes maakt of zich kwetsend uitlaat over een zorgverlener of
                                                            zorgverleners is het van groot belang niet stil te blijven, maar deze vorm van discriminatie of racisme te benoemen. Acceptatie en steun zijn dan cruciaal.
                                                            Werkgevers hebben een belangrijke
                                                            rol bij het voorkomen van discriminatie op de werkvloer.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold color-dark mb-0">4.a Discriminatie</p>

                                                <p class="bold mb-0">
                                                    4.a.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van discriminatie op de werkplek?
                                                </p>
                                                <p v-html="getAnswer('4.a.a') + getScore('4.a.a')"></p>

                                                <p class="bold mb-0">
                                                    4.a.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.a.b') + getScore('4.a.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.a.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.a.a').value !== null) || this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.a.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.a.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                De Inspectie SZW heeft samen met TNO een checklist ontwikkeld waarmee werkgevers de risico’s op discriminatie in hun organisatie in kaart kunnen brengen.
                                                                Daarnaast geeft de checklist ook tips en suggesties over hoe discriminatie voorkomen kan worden. <a href="/uploads/wegwijzerongewenstgedrag-tno-en-discrimnatie-werkplek.pdf" target="_blank">Je download de checklist hier</a>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Seksuele intimidatie kan verschillende vormen aannemen, waaronder de onderstaande drie, maar nadrukkelijk ook combinaties daarvan zijn mogelijk (zie
                                                            <a href="https://arboportaal.nl" target="_blank">arboportaal.nl</a>).
                                                        </p>
                                                        <ul>
                                                            <li>Woorden (verbale intimidatie): Denk hier aan seksueel getinte opmerkingen, grappen, intieme vragen stellen of toespelingen;</li>
                                                            <li>Gebaren, gezichtsuitdrukking (non-verbaal): Bijvoorbeeld staren, seksueel gerichte gebaren, of het tonen van seksueel getinte of pornografische afbeeldingen
                                                                (ook
                                                                via
                                                                mail, WhatsApp of sms);
                                                            </li>
                                                            <li>Direct lichamelijk contact (fysiek): Hieronder worden alle vormen van ongewenst lichamelijk contact verstaan. Dit kan een arm om de schouder zijn, beetpakken,
                                                                knijpen,
                                                                zoenen, de weg versperren, aanranden of zelfs verkrachten. In iemands ‘comfortzone’ komen kan ook een vorm van fysieke seksuele intimidatie zijn.
                                                            </li>
                                                        </ul>
                                                        <p class="mb-0">
                                                            Seksuele intimidatie kan opzettelijk plaatsvinden, maar ook onbedoeld. Dit maakt het soms een lastig te herkennen probleem. Het gaat om hoe de ontvanger het
                                                            ervaart: wat voor de één onschuldig is, kan voor de ander als vervelend, ongewenst, of intimiderend ervaren worden. Dat laatste is dan bepalend.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold color-dark mb-0">4.b Seksuele intimidatie</p>

                                                <p class="bold mb-0">
                                                    4.b.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van seksuele intimidatie op de werkplek?
                                                </p>
                                                <p v-html="getAnswer('4.b.a') + getScore('4.b.a')"></p>

                                                <p class="bold mb-0">
                                                    4.b.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.b.b') + getScore('4.b.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.b.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.b.a').value !== null) || this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.b.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.b.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <ol class="sub text-justify">
                                                                <li>
                                                                    <a href="https://www.arbocatalogusvvt.nl/thema/ongewenst-gedrag-collegas-leiding/seksuele-intimidatie/algemeen/"
                                                                       target="_blank">https://www.arbocatalogusvvt.nl/thema/ongewenst-gedrag-collegas-leiding/seksuele-intimidatie/algemeen</a><br><br>
                                                                </li>
                                                                <li>
                                                                    Er is een overzichtelijke ‘wegwijzer seksuele intimidatie’ gemaakt die u <a href="/uploads/WegwijzerSeksueleIntimidatie.pdf" target="_blank">hier kunt
                                                                                                                                                                                                                 downloaden</a>.<br><br>
                                                                </li>
                                                                <li>
                                                                    Gebruik vooral ook het <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wat-is" target="_blank">Arboportaal</a> voor dit onderwerp.
                                                                    Het bevat meer gedetailleerde informatie en tips.<br><br>
                                                                </li>
                                                                <li>
                                                                    Wat zegt de wet over seksuele intimidatie?<br>
                                                                    <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wet " target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wet</a><br><br>
                                                                </li>
                                                                <li>
                                                                    Maatregelen tegen seksuele intimidatie op het werk<br>
                                                                    <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/maatregelen" target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/maatregelen</a><br><br>
                                                                </li>
                                                                <li>
                                                                    Handige tools en instrumenten<br>
                                                                    <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/handige-tools-en-instrumenten" target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/handige-tools-en-instrumenten</a><br><br>
                                                                </li>
                                                                <li>
                                                                    Free Learning module over intimiteit en seksualiteit in het verpleeghuis<br>
                                                                    <a href="https://www.free-learning.nl/modules/intimiteit-en-seksualiteit-in-het-verpleeghuis/start.html" target="_blank">https://www.free-learning.nl/modules/intimiteit-en-seksualiteit-in-het-verpleeghuis/start.html</a>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Agressie door cliënten, familie, bezoek of anderen waaronder ook collega’s, kan het risico op het krijgen van klachten en uitval verhogen. Werkgevers zijn op grond
                                                            van deze wet verplicht om een beleid te voeren dat erop gericht is om deze vorm van arbeidsbelasting te voorkomen of te beperken. Het Arbo-besluit werkt deze
                                                            verplichting in afdeling 4, artikel 2.15 verder uit. Er moeten in elk geval maatregelen ter voorkoming van agressie op de werkvloer worden opgenomen in een plan van
                                                            aanpak. Werknemers moeten aantoonbaar voorgelicht zijn over de risico’s en de maatregelen die de organisatie heeft getroffen.
                                                        </p>
                                                        <p>
                                                            Maar in dit geval beschermt ook het Burgerlijk Wetboek werknemers meer impliciet tegen agressie. Op grond van artikel 7:658 heeft de werkgever een zorgplicht voor
                                                            de veiligheid van de werkomgeving.
                                                            Als een werknemer slachtoffer wordt op grond van zijn godsdienst, levensovertuiging, politieke gezindheid, ras, geslacht, nationaliteit, hetero- of homoseksuele
                                                            geaardheid of burgerlijke staat, dan wordt hij ook beschermd door de Algemene wet gelijke behandeling. Werknemers kunnen in dit geval ook een klacht indienen bij
                                                            het College voor de Rechten van de Mens.
                                                        </p>
                                                        <p>
                                                            In het geval van fysiek geweld valt dit onder het Wetboek van Strafrecht, want dit is een strafbaar feit. Het is daarom nodig om bij fysiek geweld aangifte van te
                                                            doen bij de politie.
                                                        </p>
                                                        <p>
                                                            Bron: <a href="https://arboportaal.nl" target="_blank">arboportaal.nl</a>
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold color-dark mb-0">4.c Agressie en geweld</p>
                                                <p>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van agressie en geweld op de werkplek?</p>

                                                <p class="bold mb-0">
                                                    4.c.a Voor het omgaan met agressieve cliënten?
                                                </p>
                                                <p v-html="getAnswer('4.c.a') + getScore('4.c.a')"></p>

                                                <p class="bold mb-0">
                                                    4.c.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.c.b') + getScore('4.c.b')"></p>

                                                <p class="bold mb-0">
                                                    4.c.c Voor het omgaan met agressieve bezoekers of anderen?
                                                </p>
                                                <p v-html="getAnswer('4.c.c') + getScore('4.c.c')"></p>

                                                <p class="bold mb-0">
                                                    4.c.d Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.c.d') + getScore('4.c.d')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.c.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.a').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.c.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.b').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.c.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.c').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.c.d').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.d').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <ol class="mt-0 sub text-justify">
                                                                <li>
                                                                    Op <a href="https://www.blijfinzetbaar.nl/" target="_blank">Blijf Inzetbaar</a> vindt u relevant en praktisch materiaal om agressie in de basiszorg te
                                                                    voorkomen.
                                                                </li>
                                                                <li>
                                                                    Kijk ook op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> voor e-learning modules over het voorkomen en omgaan met agressie.
                                                                    <ul>
                                                                        <li><a href="https://www.free-learning.nl/modules/omgaan-met-agressie/start" target="_blank">Omgaan met agressie</a></li>
                                                                        <li><a href="https://www.free-learning.nl/modules/probleemgedrag/start" target="_blank">Probleemgedrag I</a></li>
                                                                        <li><a href="https://www.free-learning.nl/modules/probleemgedragII/start" target="_blank">Probleemgedrag II</a></li>
                                                                    </ul>
                                                                    <br>
                                                                </li>
                                                                <li>
                                                                    De Inspectie SZW heeft een korte brochure gemaakt met de punten waar zij op letten bij inspecties op dit arborisico: De aanpak van agressie en geweld
                                                                    Waar let de Inspectie SZW op? Deze kunt u <a href="/uploads/Aanpak-agressie-en-geweld-waar-let-deInspectie-SZW-op.pdf" target="_blank">hier downloaden</a>.
                                                                </li>
                                                                <li>De al genoemde website Arboportaal.nl bevat erg veel informatie over deze aspecten.</li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Er is sprake van pesten als er herhaald ongewenst negatief gedrag is waartegen iemand niet in staat is zich te verdedigen. Pesten is soms lastig te definiëren en er
                                                            is soms een geleidelijke overgang naar agressie en geweld.
                                                        </p>
                                                        <p>
                                                            Voor wat betreft ‘pesten’ is er qua formele eisen aan het organisatiebeleid een sterke overlap met agressie en geweld (zie aldaar). Ook voor pesten geldt dat er
                                                            aantoonbaar beleid moet zijn en dat werknemers voorgelicht moeten zijn.
                                                        </p>
                                                        <p class="mb-0">
                                                            Pesten op het werk is een serieus probleem. Meer dan een half miljoen mensen zeggen wel eens gepest te worden op het werk. De grens van wat pesten is en wanneer het
                                                            meer gaat om ‘grapjes’ is soms lastig te bepalen. En ook iets als negeren en uitsluiten valt onder ‘pesten’. De perceptie van de ontvanger is dan bepalend.
                                                            <a href="/uploads/factsheet pesten.jpg" target="_blank">Er is een factsheet gemaakt over pesten als probleem</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="bold color-dark mb-0">4.d Pesten</p>

                                                <p class="bold mb-0">
                                                    4.d.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van pesten op de werkplek?
                                                </p>
                                                <p v-html="getAnswer('4.d.a') + getScore('4.d.a')"></p>

                                                <p class="bold mb-0">
                                                    4.d.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.d.b') + getScore('4.d.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.d.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.d.a').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.d.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.d.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Er is een Wegwijzer Pesten gemaakt met waardevolle tips en adviezen. <a href="/uploads/WegwijzerPesten.pdf" target="_blank">Deze kunt u hier downloaden</a>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Werkdruk en zeker beleving van werkdruk wordt meestal ook onder PSA geschaard. Werkdruk is een goede voorspeller voor duurzame inzetbaarheid, verzuim, burn-out en
                                                            vervroegd uittreden uit het beroep van zorgverlener. En, andersom, er zijn aanwijzingen voor een direct verband tussen werkdruk en indicatoren voor de kwaliteit van
                                                            zorg, uitkomstmaten voor de patiënt en de door zorgverleners gerapporteerde kwaliteit van zorg.
                                                        </p>
                                                        <p>
                                                            Werkdruk ontstaat als een werknemer structureel niet kan voldoen aan de eisen die het werk aan hem stelt. Bijvoorbeeld wanneer een werknemer vaak of voortdurend
                                                            onvoldoende tijd heeft om zijn werk af te krijgen of onmogelijk het gewenste resultaat kan halen. Zeker wanneer een werknemer hier zelf niets aan kan veranderen, is
                                                            er
                                                            al snel sprake van te hoge werkdruk. Werknemers die langdurig te maken hebben met te hoge werkdruk raken niet alleen gestrest, maar veelal ook gedemotiveerd. Als
                                                            zij
                                                            uitvallen, wordt de stap terug naar werk daardoor extra moeilijk.
                                                        </p>
                                                        <p class="mb-0">
                                                            Het ontbreekt aan een gouden standaard voor de aanpak van werkdruk, maar cruciaal is dat er maatwerk nodig is en dat u actief beleid voert. De criteria daarvoor
                                                            zijn
                                                            echter weinig vastomlijnd en hangen ook sterk af van de situatie in uw organisatie. In een thuiszorgorganisatie ziet dat er heel anders uit dan in bijvoorbeeld op
                                                            een
                                                            Spoed Eisende Hulp. We geven een aantal handvatten en verwijzen naar de website <a href="https://www.arboportaal.nl/onderwerpen/werkdruk" target="_blank">arboportaal.nl</a> en <a
                                                            href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="bold color-dark mb-0">4.e Werkdruk en duurzame inzetbaarheid</p>

                                                <p class="bold mb-0">
                                                    4.e.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van werkdruk?
                                                </p>
                                                <p v-html="getAnswer('4.e.a') + getScore('4.e.a')"></p>

                                                <p class="bold mb-0">
                                                    4.e.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('4.e.b') + getScore('4.e.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.e.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.e.a').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('4.e.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.e.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <ol class="text-justify sub">
                                                                <li>
                                                                    <strong>Basisinspectiemodule (BIM) Werkdruk</strong><br>
                                                                    Dit is het <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2017/12/KoersKaart-DI.pdf" target="_blank">document</a> dat de Inspectie SZW gebruikt voor haar inspectiebeleid.<br><br>
                                                                </li>
                                                                <li>
                                                                    <strong>Zelfinspectie Werkdruk</strong><br>
                                                                    Doet u voldoende tegen te hoge werkdruk? Gebruik de zelfinspectietool Werkdruk en Ongewenst gedrag. Ga naar <a
                                                                    href="https://www.zelfinspectie.nl/zelfinspectie/werkdruk-en-ongewenst-gedrag/te-hoge-werkdruk" target="_blank">werkdrukenongewenstgedrag.zelfinspectie.nl</a>.<br><br>
                                                                </li>
                                                                <li>
                                                                    <strong>BalansRadar</strong><br>
                                                                    Van den Oetelaar et al. (2016) onderzocht manieren om de werkdruk van verpleegkundigen te managen en de invloed van de patiënt daarop te kunnen
                                                                    reguleren. Diverse complexe systemen blijken niet voldoende in staat om dit te kunnen en/of blijken een te grote administratie en registratielast voor
                                                                    zorgverleners met zich mee te brengen: het is en blijft dus lastig de bezetting optimaal te laten zijn. Ook Myny et al. voerden zeer gedetailleerd
                                                                    onderzoek uit. Zij destilleerden belangrijke patiënt-gebonden factoren: 1. het aantal werkonderbrekingen, 2. het verloop onder de patiëntengroep en 3.
                                                                    het aantal uit te voeren registraties (registratielast).<br><br>
                                                                    Om hier rekening mee te houden in de zorg werd de BalansRadar ontwikkeld. De BalansRadar bouwt voort op deze onderzoeken en helpt bij het leveren van
                                                                    die fine-tuning, verfijning, onderbouwing en bijsturing om alert te reageren op discrepanties tussen personele bezetting, (ervaren) werkdruk en
                                                                    wijzigingen in de werkdruk. Dat zou dan moeten leiden tot aanpassing in de toewijzing van formatie of tot andersoortige maatregelen (zoals meer
                                                                    hulpmiddelen of een andere methode van wondverzorgen etc.).
                                                                    In de linkjes hieronder vindt u de instrumenten.
                                                                    <ul>
                                                                        <li><a href="/uploads/Stappenplan-BR.docx" target="_blank">Stappenplan BalansRadar</a></li>
                                                                        <li><a href="/uploads/Werkdruk-meten-De-BalansRadar.pdf" target="_blank">Werkdruk meten, De BalansRadar</a></li>
                                                                        <li><a href="/uploads/Toelichting-vragen.doc" target="_blank">Toelichting vragen</a></li>
                                                                        <li><a href="/uploads/BR-Individu.xlsx" target="_blank">Excel BalansRadar Individu</a></li>
                                                                        <li><a href="/uploads/BR-groep.xlsx" target="_blank">Excel BalansRadar Groep</a></li>
                                                                    </ul>
                                                                    <br><br>
                                                                </li>
                                                                <li>
                                                                    <strong>Koerskaart Duurzame Inzetbaarheid</strong><br>
                                                                    <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2017/12/KoersKaart-DI.pdf" target="_blank">Deze praktische koerskaart</a>
                                                                    maakt het mogelijk om op individuele basis het gesprek aan te gaan of het bewustzijn over de noodzaak van duurzame inzetbaarheid te vergroten.<br><br>
                                                                </li>
                                                                <li>
                                                                    <strong>Platform Duurzame Inzetbaarheid</strong><br>
                                                                    <a href="https://www.duurzameinzetbaarheid.nl/" target="_blank">Dit platform</a> geeft veel informatie over alles op dit themagebied.<br><br>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        <span class="accordion-title">
                                            5. Training en instructie
                                        </span>
                                    </div>
                                    <div id="collapse5" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Bij meerdere vragen in deze BeleidsSpiegel is aangegeven dat de werkgever de verplichting heeft om werknemers te scholen en voor te lichten over Arbo-risico’s en de
                                                            maatregelen die de zorgorganisatie heeft genomen om gezondheidsschade te voorkomen (Artikel 8 van de Arbo-wet). Het gaat er bij deze vraag om dat u een doeltreffend
                                                            scholings- en trainingsbeleid heeft en ook dat u, bij een eventuele inspectie, kunt aantonen dat dit het geval is. Er zijn geen concrete eisen aan de frequentie,
                                                            duur of inhoud van die scholing. Dat wat optimaal is, zal per zorgbranche en per zorgorganisatie wisselen.
                                                        </p>
                                                        <p>
                                                            Als werkgever moet u een registratie kunnen overleggen van welke voorlichting of instructie door wie is gegeven en ontvangen.
                                                        </p>
                                                        <p class="mb-0">
                                                            Van belang is daarnaast dat een eenmalige voorlichting en instructie natuurlijk niet voldoende is. Regelmatig opfrissen van de informatie bij de werknemers is
                                                            daarom van belang. Hoe vaak dit moet is niet vastgelegd.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">
                                                    5.a Worden zorgverleners aantoonbaar voorgelicht en getraind met betrekking PSA risico’s?
                                                </p>
                                                <p v-html="getAnswer('5.a') + getScore('5.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('5.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('5.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub text-justify">
                                                                <p>
                                                                    Arbo-wet informatie over Voorlichting en onderricht<br>
                                                                    <i>Artikel 8</i>
                                                                </p>
                                                                <ol>
                                                                    <li>De werkgever zorgt ervoor dat de werknemers doeltreffend worden ingelicht over de te verrichten werkzaamheden en de daaraan verbonden risico's,
                                                                        alsmede over de maatregelen die erop gericht zijn deze risico's te voorkomen of te beperken. Tevens zorgt de werkgever ervoor dat de werknemers
                                                                        doeltreffend worden ingelicht over de wijze waarop de deskundige bijstand, bedoeld in de artikelen 13, 14, 14a en 15, in zijn bedrijf of inrichting
                                                                        is georganiseerd.
                                                                    </li>
                                                                    <li>
                                                                        De werkgever zorgt ervoor dat aan de werknemers doeltreffend en aan hun onderscheiden taken aangepast onderricht wordt verstrekt met betrekking tot
                                                                        de arbeidsomstandigheden.
                                                                    </li>
                                                                    <li>
                                                                        Indien persoonlijke beschermingsmiddelen ter beschikking van de werknemers worden gesteld en indien op arbeidsmiddelen of anderszins beveiligingen
                                                                        zijn aangebracht, zorgt de werkgever ervoor dat de werknemers op de hoogte zijn van hun doel en werking en de wijze waarop zij deze dienen te
                                                                        gebruiken.
                                                                    </li>
                                                                    <li>
                                                                        De werkgever ziet toe op de naleving van de instructies en voorschriften gericht op het voorkomen of beperken van de in het eerste lid genoemde
                                                                        risico's alsmede op het juiste gebruik van persoonlijke beschermingsmiddelen.
                                                                    </li>
                                                                    <li>
                                                                        Indien binnen de onderneming werknemers jonger dan 18 jaar werkzaam zijn, houdt de werkgever bij de uitvoering van de in de voorgaande leden
                                                                        genoemde verplichtingen in het bijzonder rekening met de aan de jeugdige leeftijd inherente beperkte werkervaring en onvoltooide lichamelijke en
                                                                        geestelijke ontwikkeling van deze werknemers.
                                                                    </li>
                                                                </ol>
                                                                <p class="mb-0">
                                                                    Artikel 11 meldt dat werknemers, omgekeerd, de verplichting hebben om mee te werken aan deze scholingen: ‘mede te werken aan het voor hem georganiseerde
                                                                    onderricht bedoeld in artikel 8’.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        <span class="accordion-title">
                                            6. Overgang
                                        </span>
                                    </div>
                                    <div id="collapse6" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Hoewel de overgang geen ziekte is, maar een natuurlijke fase in het leven van een vrouw, hebben veel vrouwen tussen de 45-55 jaar er in meer of mindere mate last
                                                            van. Soms zo erg dat het leidt tot verzuim of minder plezier in het werk. In de zorg is rond de 65% van de vrouwen in de overgang. De overgang kan gepaard gaan met
                                                            de klassieke verschijnselen zoals ’s nachts transpireren, opvliegers, stemmingswisselingen en vergeetachtigheid. Drie van de vier vrouwen hebben er last van en één
                                                            van de vier heeft ernstige klachten. Soms zijn de klachten zelfs vergelijkbaar met burnout klachten.
                                                        </p>
                                                        <p>
                                                            Desondanks lijkt er nog steeds een taboe op te rusten. Vrouwen maken hun problemen rondom de overgang nauwelijks bespreekbaar. Bijvoorbeeld omdat ze bang zijn dat
                                                            er in het team lacherig over wordt gedaan. Of omdat ze het zelf als een teken van verval zien. Of omdat ze een drempel ervaren het te melden aan een leidinggevende,
                                                            die bijvoorbeeld man en jonger is. Toch is die openheid nodig om elkaar te kunnen ondersteunen, verzuim te voorkomen en met plezier te kunnen blijven werken.
                                                        </p>
                                                        <p class="mb-0">
                                                            Bijzonder is dat er geen specifieke richtlijnen zijn vastgelegd in de Arbo-wet of het inspectiebeleid. Ook in veel Arbocatalogi staat nauwelijks of helemaal geen
                                                            informatie over de menopauze en hoe daar in een zorgorganisatie mee om te gaan. Dat neemt niet weg dat het belangrijk blijft om beleid op dit punt uit te voeren.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">
                                                    6.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van het ondersteunen van werknemers die last ondervinden van de overgang / menopauze?
                                                </p>
                                                <p v-html="getAnswer('6.a') + getScore('6.a')"></p>

                                                <p class="bold mb-0">
                                                    6.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('6.b') + getScore('6.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('6.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('6.a').value !== null) ||
                                                        this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('6.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('6.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub text-justify">
                                                                <ol>
                                                                    <li>
                                                                        Gezond & Zeker heeft het BlijfGezond boekje uitgebracht waarin expliciet aandacht is voor de overgang van zorgmedewerkers. Het kan daar besteld
                                                                        worden (<a href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>).
                                                                    </li>
                                                                    <li>
                                                                        IZZ heeft een dossier opgebouwd over de overgang in de zorg en onder meer een stappenplan ontwikkeld. Globaal gaat het om de volgende
                                                                        stappen. Meer specifieke informatie vindt u op de site van IZZ (<a href="https://izz.nl/overgang" target="_blank">www.izz.nl/overgang</a>)
                                                                        <ul>
                                                                            <li>
                                                                                <strong>Stap 1</strong><br>
                                                                                Weet wat er speelt (hoeveel vrouwen in de overgangsleeftijd werken er in jouw zorgorganisatie? En wat is de invloed van de overgang op jouw
                                                                                zorgorganisatie?).
                                                                            </li>
                                                                            <li>
                                                                                <strong>Stap 2</strong><br>
                                                                                Maak het belangrijk (haal het taboe eraf, geef aandacht aan de overgang, zet het op de agenda).
                                                                            </li>
                                                                            <li>
                                                                                <strong>Stap 3</strong><br>
                                                                                Kom in actie (herken en erken de overgang).
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        Er is een overzichtsstudie verschenen naar de invloed van overgang op het werk. <a href="/uploads/Literatuurverkenning naar overgang.pdf"
                                                                                                                                                           target="_blank">Deze kunt u
                                                                                                                                                                           hier downloaden</a>.
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                <span class="accordion-title">
                                    7. Ouder worden en PSA-beleid
                                </span>
                                    </div>
                                    <div id="collapse7" class="collapse">
                                        <div class="accordion-body">

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            Om een zo hoog mogelijke duurzame inzetbaarheid te realiseren is het, gezien de stijgende leeftijd van zorgverleners en de daaraan gekoppelde extra risico’s op
                                                            verzuim en uitstroom, steeds belangrijker om werklastbeheersing en leeftijdsbewust vitaliteits- en personeelsbeleid in praktijk te brengen. Oudere zorgverleners
                                                            brengen wel veel kennis en ervaring met zich mee die waardevol is voor elke organisatie. Het behoud van deze zorgverleners vergt extra aandacht voor belastbaarheid,
                                                            motivatie en ontwikkeling, om uitval te voorkomen en de inzetbaarheid op peil te houden. Er zijn echter geen specifieke eisen in de Arbowet vastgelegd, anders dan
                                                            de gewone eisen die dan in versterkte mate gelden voor ouder wordende zorgverleners.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">
                                                    7.a Is er in uw organisatie beleid op schrift aanwezig ten aanzien van ouder wordende werknemers?
                                                </p>
                                                <p v-html="getAnswer('7.a') + getScore('7.a')"></p>

                                                <p class="bold mb-0">
                                                    7.b Wordt dit altijd / meestal in praktijk gebracht?
                                                </p>
                                                <p v-html="getAnswer('7.b') + getScore('7.b')"></p>
                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('7.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.a').value !== null) ||
                                                         this.showTips && (this.$store.getters['psychosocialStore/getQuestionById']('7.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.b').value !== null)">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub text-justify">
                                                                <ol>
                                                                    <li>
                                                                        Gezond & Zeker heeft het BlijfGezond boekje uitgebracht waarin expliciet aandacht is voor de ouder wordende zorgmedewerker. Het kan daar besteld
                                                                        worden (<a href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>)
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.arboportaal.nl/onderwerpen/ouderen" target="_blank">Op deze site</a> treft u meer achtergrondinformatie en tips aan.
                                                                    </li>
                                                                    <li>
                                                                        Door het hanteren van à-la-carte-systemen (keuzemenu’s) kunnen werknemers aangeven wat zij nodig hebben om gezond ouder te blijven werken. Enkele
                                                                        voorbeelden (zie <a href="https://arboportaal.nl" target="_blank">arboportaal</a>):
                                                                        <ul>
                                                                            <li>(om)scholing;</li>
                                                                            <li>(meer) thuiswerken;</li>
                                                                            <li>langere werkdagen (4x9 uur) afgewisseld met meer vrije dagen;</li>
                                                                            <li>flexibele contracten (oproepkrachten voor piekperiodes);</li>
                                                                            <li>verlofdagen (ver)kopen;</li>
                                                                            <li>sparen voor sabbatical.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                <span class="accordion-title">
                                    8. Werkruimtes en veiligheid
                                </span>
                                    </div>
                                    <div id="collapse8" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p>
                                                            De omgeving en ruimte waar gewerkt wordt is sterk van invloed op de beleving van het werk, stress en ontspanning. Deze invloed wordt nog wel eens onderschat, maar
                                                            biedt veel mogelijkheden.
                                                        </p>
                                                        <p>
                                                            Vanuit de Arbo-wet zijn er geen concrete eisen op dit punt, maar er zijn veel mogelijkheden om werkruimtes in te richten om deze optimaal te laten zijn.
                                                        </p>
                                                        <p class="mb-0">
                                                            Behalve de meer subtiele zaken zoals kleuren, planten, licht, frisse lucht kan het ook heel concreet gaan om veiligheid en het voorkomen van agressie en geweld. Als
                                                            voorbeeld geldt dat verlichting het gevoel van sociale veiligheid en werkdruk kan beïnvloeden. Maar ook de manier waarop bijv. een spoed eisende hulp wordt
                                                            ingericht is
                                                            zeer bepalend voor het gevoel van veiligheid en werkdruk van zorgverleners die daar werken.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">
                                                    8.a Zijn de ruimtes en de inrichting daarvan geschikt om gezond en veilig te kunnen werken?
                                                </p>
                                                <p v-html="getAnswer('8.a') + getScore('8.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('8.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('8.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub text-justify">
                                                                <ol>
                                                                    <li>
                                                                        Vanuit de internationaal geaccepteerde CPTED (Crime Prevention Through Environmental Design) benadering wordt verlichting vooral gezien als
                                                                        technisch middel om meer mogelijkheden te schepen voor ‘natural surveillance’. <a
                                                                        href="https://www.omgevingspsycholoog.nl/sociale-veiligheid-verbeteren-met-verlichting/" target="_blank">Een artikel door Randy Bloeme</a> gaat daar
                                                                        dieper op in.
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.bouwenaandezorg.eu/onderwerp/woonzorg-verpleeghuizen/" target="_blank">Op deze website</a>
                                                                        kunt u veel inspiratie opdoen over inrichting en bouwen van ruimtes in de zorg en de invloed op de beleving door zorgverleners en cliënten;
                                                                        kleurgebruik, materiaal gebruik en verlichting.
                                                                    </li>
                                                                    <li>
                                                                        Het UKON (Universitair Kennisnetwerk Ouderen Nijwegen ontwikkelde een praktische methode voor verpleeghuizen <a href="https://www.zorgvoorbeter.nl/dementie/persoonsgerichte-zorg/leefomgeving?utm_medium=email" target="_blank">om te werken aan een veilig en gezond thuis</a>.
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.free-learning.nl/modules/samen-werken-aan-een-thuis/start.html" target="_blank">De Free Learning ‘Samen werken aan een thuis’</a> gaat over hoe je de leefomgeving van bewoners met dementie gezond en veilig kunt maken.
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                <span class="accordion-title">
                                    9. Begroting
                                </span>
                                    </div>
                                    <div id="collapse9" class="collapse">
                                        <div class="accordion-body">
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <p class="mb-0">
                                                            Om een succesvol beleid psychosociale arbeidsbelasting te kunnen voeren is het belangrijk hiervoor jaarlijks een reële post op te nemen in de begroting of uren te
                                                            oormerken voor dit doel.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="bold mb-0">
                                                    9.a Wordt er in elke begroting rekening gehouden met een reële post voor het terugdringen of aanvaardbaar houden van psychosociale arbeidsbelasting?
                                                </p>
                                                <p v-html="getAnswer('9.a') + getScore('9.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['psychosocialStore/getQuestionById']('9.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('9.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <div class="sub text-justify">
                                                                <p class="mb-0">
                                                                    De omvang van de post hangt af van de problematiek die is gesignaleerd en de doelstellingen die op grond daarvan zijn geformuleerd. Belangrijk is echter
                                                                    dat hiervoor een basisbedrag gereserveerd wordt, dat jaarlijks kan worden geëvalueerd en bijgesteld.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="btn-container text-center">
                            <a href="#" @click="exportData" class="btn btn-grad mt-3">Download als PDF<i class="fas fa-arrow-down"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            <Progress />
            <Footer v-if="this.exporting"></Footer>
        </div>
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import PsychoSocialResultChart from "../includes/PsychosocialResultChart";
import domtoimage from 'dom-to-image-more';
import {jsPDF} from 'jspdf';
import Footer from "@/components/defaults/Footer";

export default {
    name:       'PsychosocialResults',
    components: {
        Footer,
        PsychoSocialResultChart,
        Progress
    },
    computed:   {
        name() {
            return this.$store.getters['psychosocialStore/getName']();
        },
        organizationName() {
            return this.$store.getters['psychosocialStore/getOrganizationName']();
        },
        date() {
            let date = new Date();

            return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
        }
    },
    data() {
        return {
            resultsChartKey:  0,
            showExplanations: true,
            showTips:         true,
            exporting:        false,
        }
    },
    methods: {
        forceRerender() {
            this.resultsChartKey += 1;
        },
        getAnswer(id) {
            const question      = this.$store.getters['psychosocialStore/getQuestionById'](id);
            const questionValue = question.value === null ? '<i>Niet ingevuld</i>' : question.value;

            if(questionValue === 1) {
                return '<span class="color-green">' + ((question.textValue !== null) ? question.textValue : 'Ja') + '</span>';
            }

            if(questionValue === 0) {
                return '<span class="color-red">' + ((question.textValue !== null) ? question.textValue : 'Nee') + '</span>';
            }

            if(questionValue === 0.5) {
                return '<span class="color-primary">' + ((question.textValue !== null) ? question.textValue : 'Deels') + '</span>';
            }

            return questionValue;
        },
        getScore(id) {
            const question      = this.$store.getters['psychosocialStore/getQuestionById'](id);
            const questionValue = question.value === null ? '<i>Niet ingevuld</i>' : question.value;

            if(questionValue === 1) {
                return '<span class="ml-3">Score: <span class="color-green">' + questionValue + '</span></span>';
            }

            if(questionValue === 0) {
                return '<span class="ml-3">Score: <span class="color-red">' + questionValue + '</span></span>';
            }

            if(questionValue === 0.5) {
                return '<span class="ml-3">Score: <span class="color-primary">' + questionValue + '</span></span>';
            }

            return questionValue;
        },
        getValueAnswer(id) {
            const question = this.$store.getters['psychosocialStore/getQuestionById'](id);
            return question.value;
        },
        exportData(e) {
            e.preventDefault();

            document.querySelectorAll('.collapse').forEach(element => {
                element.classList.add('show');
            });
            document.getElementById('overlay-export').style.opacity = 1;
            document.querySelector('.progress').style.display       = 'none';
            document.querySelector('.first-section').classList.remove('pt-12');
            document.querySelector('.first-section').classList.add('pt-3');

            const exportable = document.getElementById('exportable');

            e.currentTarget.style.display = 'none';
            exportable.style.width        = '1100px';

            const targetElement = event.currentTarget;

            this.exporting = true;
            const vm       = this;

            domtoimage.toPng(exportable).then(function(imgData) {
                const img = new Image();
                img.src   = imgData;

                img.onload = () => {
                    const imgWidth   = 210;
                    const pageHeight = 295;
                    const imgHeight  = parseInt(img.height) * parseInt(imgWidth) / parseInt(img.width);
                    let heightLeft   = imgHeight;

                    const doc    = new jsPDF('p', 'mm');
                    let position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, null, 'MEDIUM');
                    heightLeft -= pageHeight;

                    while(heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    doc.save('uw-resultaten.pdf', { returnPromise: true }).then(() => {
                        targetElement.style.removeProperty('display');
                        exportable.style.removeProperty('width');
                        document.querySelector('.progress').style.removeProperty('display');
                        document.getElementById('overlay-export').style.removeProperty('opacity');
                        document.querySelector('.first-section').classList.add('pt-12');
                        document.querySelector('.first-section').classList.remove('pt-3');

                        vm.exporting = false;
                    });
                };
            }.bind(this));
        },
    },
    mounted() {
        this.forceRerender();
    },
    activated() {
        this.forceRerender();
    }
}
</script>
