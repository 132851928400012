<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 5.10 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">7. Ouder worden en PSA-beleid</h3>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Om een zo hoog mogelijke duurzame inzetbaarheid te realiseren is het, gezien de stijgende leeftijd van zorgverleners en de daaraan gekoppelde extra risico’s op
                                    verzuim en uitstroom, steeds belangrijker om werklastbeheersing en leeftijdsbewust vitaliteits- en personeelsbeleid in praktijk te brengen. Oudere zorgverleners
                                    brengen wel veel kennis en ervaring met zich mee die waardevol is voor elke organisatie. Het behoud van deze zorgverleners vergt extra aandacht voor belastbaarheid,
                                    motivatie en ontwikkeling, om uitval te voorkomen en de inzetbaarheid op peil te houden. Er zijn echter geen specifieke eisen in de Arbowet vastgelegd, anders dan
                                    de gewone eisen die dan in versterkte mate gelden voor ouder wordende zorgverleners.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van ouder wordende werknemers?</h4>
                        <ChoiceField id="7.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="7.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('7.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.a').value !== null) ||
                                        (this.$store.getters['psychosocialStore/getQuestionById']('7.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('7.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <div class="sub text-justify">
                                            <ol>
                                                <li>
                                                    Gezond & Zeker heeft het BlijfGezond boekje uitgebracht waarin expliciet aandacht is voor de ouder wordende zorgmedewerker. Het kan daar besteld
                                                    worden (<a href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>)
                                                </li>
                                                <li>
                                                    <a href="https://www.arboportaal.nl/onderwerpen/ouderen" target="_blank">Op deze site</a> treft u meer achtergrondinformatie en tips aan.
                                                </li>
                                                <li>
                                                    Door het hanteren van à-la-carte-systemen (keuzemenu’s) kunnen werknemers aangeven wat zij nodig hebben om gezond ouder te blijven werken. Enkele
                                                    voorbeelden (zie <a href="https://arboportaal.nl" target="_blank">arboportaal</a>):
                                                    <ul>
                                                        <li>(om)scholing;</li>
                                                        <li>(meer) thuiswerken;</li>
                                                        <li>langere werkdagen (4x9 uur) afgewisseld met meer vrije dagen;</li>
                                                        <li>flexibele contracten (oproepkrachten voor piekperiodes);</li>
                                                        <li>verlofdagen (ver)kopen;</li>
                                                        <li>sparen voor sabbatical.</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-8'}" class="btn btn-grad mt-3">8. Werkruimtes en veiligheid <i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep7',
    components: { ChoiceField, Progress }
}
</script>
