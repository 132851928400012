<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 4.9 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">4. Vertaling Praktijkrichtlijnen naar zorghandelingen.</h3>
                        <h4>9a. Wordt er van alle mogelijk fysiek belastende handelingen rondom cliënten per cliënt vooraf een zorgvuldige inschatting van de risico's
                            gemaakt?</h4>
                        <p class="mb-5">Om ergonomisch te kunnen werken is het nodig om bij elke cliënt een zorgvuldige inschatting te maken van de risico’s die fysiek belastende
                                        handelingen met zich meebrengen. Het gaat daarbij niet alleen om de transfers, denk ook aan het manoeuvreren met rollend materiaal (bedden,
                                        tilliften, karren, etc.), het aan- of uitrekken van steunkousen en het wassen, douchen en baden. Voor elk van deze handelingen zijn
                                        Praktijkrichtlijnen Fysieke Belasting geformuleerd.</p>
                        <ChoiceField id="9.a" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op uw Arbocatalogus voor de meeste recente versie van de Praktijkrichtlijnen Fysieke Belasting zoals die uw branche
                                                            zijn afgesproken.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>9b. Wordt op basis van die inschatting een veilige keuze gemaakt in de manier waarop de zorghandeling wordt uitgevoerd?</h4>
                        <p class="mb-5">Op basis van gemaakte inschatting moeten er afspraken gemaakt worden over hoe de zorghandelingen bij de betreffende cliënt worden
                                        uitgevoerd. Deze afspraken moeten conform de Praktijkrichtlijnen Fysieke Belasting zijn.</p>
                        <ChoiceField id="9.b" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op GoedGebruik www.goedgebruik.nl voor ruim 250 instructiefilms die conform de Praktijkrichtlijnen Fysieke
                                                            Belasting
                                                            worden uitgevoerd.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>9c. Wordt bij het maken van deze afspraken het stimuleren van zelfredzaamheid van de cliënt nadrukkelijk meegenomen?</h4>
                        <p class="mb-5">Dit geldt voor cliënten met Mobiliteitsklassen A-D. Hoe groter de zelfredzaamheid van cliënten, hoe minder kans op fysieke overbelasting van
                                        de zorgverleners. Daarnaast is het zorginhoudelijk in de meeste gevallen van groot belang zelfredzaamheid te stimuleren. </p>
                        <ChoiceField id="9.c" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Met de ZelfredzaamheidsRadar check je op 15 domeinen de zelfredzaamheid van de cliënt en zoek je op basis daarvan naar
                                                            mogelijkheden om deze te verbeteren of te onderhouden. Kijk op www.zelfredzaamheidsradar.nl voor meer informatie, films
                                                            en
                                                            een Free Learning module.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>9d. Worden deze afspraken vastgelegd?</h4>
                        <p class="mb-5">Het is nodig dat de afspraken, die conform de Praktijkrichtlijnen Fysieke Belasting zijn gemaakt, worden vastgelegd in het zorgdossier.
                                        Bijvoorbeeld op een speciaal Til- of Transferprotocol.</p>
                        <ChoiceField id="9.d" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Uitgebreide achtergrondinformatie over til-, transfer- en mobiliteitsprotocollen is te vinden in de publicatie
                                                            'Transferprotocollen, hoe en wat?'</p>
                                        <a href="/uploads/transferprotocollen-2015.pdf" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>9e. Worden deze afspraken doorgaans nageleefd?</h4>
                        <p class="mb-5">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te betrekken bij het proces, maar daarin nóg een
                                        stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.</p>
                        <ChoiceField id="9.e" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.e').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te
                                                            betrekken
                                                            bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen
                                                            op te
                                                            lossen. De Voilà!-methode biedt hiervoor bijvoorbeeld een goede structuur.</p>
                                        <a href="https://www.locomotion.nl/voila-methode" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>9f. Wordt op naleving van die afspraken toegezien?</h4>
                        <p class="mb-5">Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen het normale zorgproces, maar ook via de ErgoCoaches, de
                                        Transferprotocollen, de TilThermometer of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op
                                        zichzelf, maar levert het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee
                                        vooral een vorm van monitoring geworden en minder een vorm van controle. </p>
                        <ChoiceField id="9.f" :options="[{
                                value: 1,
                                label: 'Vrijwel altijd (> 90%)',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: '10 - 90%',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nooit (< 10%)',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('9.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.f').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'. </p>
                                        <a href="https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html" class="btn btn-hints" target="_blank">Link</a>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-5'}" class="btn btn-grad mt-3">5. Vertaling Praktijkrichtlijnen naar niet-cliënt gebonden handelingen. <i
                            class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step4',
    components: { ChoiceField, Progress }
}
</script>
