var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("4. Vertaling Praktijkrichtlijnen naar zorghandelingen.")]),_c('h4',[_vm._v("9a. Wordt er van alle mogelijk fysiek belastende handelingen rondom cliënten per cliënt vooraf een zorgvuldige inschatting van de risico's gemaakt?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om ergonomisch te kunnen werken is het nodig om bij elke cliënt een zorgvuldige inschatting te maken van de risico’s die fysiek belastende handelingen met zich meebrengen. Het gaat daarbij niet alleen om de transfers, denk ook aan het manoeuvreren met rollend materiaal (bedden, tilliften, karren, etc.), het aan- of uitrekken van steunkousen en het wassen, douchen en baden. Voor elk van deze handelingen zijn Praktijkrichtlijnen Fysieke Belasting geformuleerd.")]),_c('ChoiceField',{attrs:{"id":"9.a","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.a').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Kijk op uw Arbocatalogus voor de meeste recente versie van de Praktijkrichtlijnen Fysieke Belasting zoals die uw branche zijn afgesproken.")])])])]):_vm._e()]),_c('h4',[_vm._v("9b. Wordt op basis van die inschatting een veilige keuze gemaakt in de manier waarop de zorghandeling wordt uitgevoerd?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Op basis van gemaakte inschatting moeten er afspraken gemaakt worden over hoe de zorghandelingen bij de betreffende cliënt worden uitgevoerd. Deze afspraken moeten conform de Praktijkrichtlijnen Fysieke Belasting zijn.")]),_c('ChoiceField',{attrs:{"id":"9.b","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.b').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Kijk op GoedGebruik www.goedgebruik.nl voor ruim 250 instructiefilms die conform de Praktijkrichtlijnen Fysieke Belasting worden uitgevoerd.")])])])]):_vm._e()]),_c('h4',[_vm._v("9c. Wordt bij het maken van deze afspraken het stimuleren van zelfredzaamheid van de cliënt nadrukkelijk meegenomen?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Dit geldt voor cliënten met Mobiliteitsklassen A-D. Hoe groter de zelfredzaamheid van cliënten, hoe minder kans op fysieke overbelasting van de zorgverleners. Daarnaast is het zorginhoudelijk in de meeste gevallen van groot belang zelfredzaamheid te stimuleren. ")]),_c('ChoiceField',{attrs:{"id":"9.c","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.c').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Met de ZelfredzaamheidsRadar check je op 15 domeinen de zelfredzaamheid van de cliënt en zoek je op basis daarvan naar mogelijkheden om deze te verbeteren of te onderhouden. Kijk op www.zelfredzaamheidsradar.nl voor meer informatie, films en een Free Learning module.")])])])]):_vm._e()]),_c('h4',[_vm._v("9d. Worden deze afspraken vastgelegd?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Het is nodig dat de afspraken, die conform de Praktijkrichtlijnen Fysieke Belasting zijn gemaakt, worden vastgelegd in het zorgdossier. Bijvoorbeeld op een speciaal Til- of Transferprotocol.")]),_c('ChoiceField',{attrs:{"id":"9.d","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.d').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Uitgebreide achtergrondinformatie over til-, transfer- en mobiliteitsprotocollen is te vinden in de publicatie 'Transferprotocollen, hoe en wat?'")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"/uploads/transferprotocollen-2015.pdf","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()]),_c('h4',[_vm._v("9e. Worden deze afspraken doorgaans nageleefd?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te betrekken bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.")]),_c('ChoiceField',{attrs:{"id":"9.e","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.e').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te betrekken bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen. De Voilà!-methode biedt hiervoor bijvoorbeeld een goede structuur.")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"https://www.locomotion.nl/voila-methode","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()]),_c('h4',[_vm._v("9f. Wordt op naleving van die afspraken toegezien?")]),_c('p',{staticClass:"mb-5"},[_vm._v("Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen het normale zorgproces, maar ook via de ErgoCoaches, de Transferprotocollen, de TilThermometer of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op zichzelf, maar levert het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee vooral een vorm van monitoring geworden en minder een vorm van controle. ")]),_c('ChoiceField',{attrs:{"id":"9.f","options":[{
                            value: 1,
                            label: 'Vrijwel altijd (> 90%)',
                            class: 'true'
                        }, {
                            value: 0.5,
                            label: '10 - 90%',
                            class: 'neutral'
                        }, {
                            value: 0,
                            label: 'Nooit (< 10%)',
                            class: 'false'
                        }]}}),_c('transition',{attrs:{"name":"fade-in-up"}},[(this.$store.getters['physicalStore/getQuestionById']('9.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.f').value !== null)?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v("Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'. ")]),_c('a',{staticClass:"btn btn-hints",attrs:{"href":"https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html","target":"_blank"}},[_vm._v("Link")])])])]):_vm._e()])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'step-5'}}},[_vm._v("5. Vertaling Praktijkrichtlijnen naar niet-cliënt gebonden handelingen. "),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])])]),_c('Progress')],1)}
var staticRenderFns = []

export { render, staticRenderFns }