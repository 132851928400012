<template>
    <section class="bg-secondary">
        <div v-if="this.$route.path.startsWith('/fysieke-belasting')">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>Partners</h2>
                        <p>Deze online BeleidsSpiegels zijn ontwikkeld door JJ Knibbe en NE Knibbe (LOCOmotion) in opdracht van de regionale werkgeversorganisaties verenigd in RegioPlus en is
                            gebaseerd op de versie van de Arbocatalogus VVT.</p>
                    </div>
                </div>
                <div class="row" data-aos="fade-up">
                    <a class="col-md-2 col-4" target="_blank" href="https://www.zw-connect.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zw-connect.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://derotterdamsezorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/de-rotterdamse-zorg.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://blijfinzetbaar.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/gezondenzeker.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://locomotion.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/locomotion.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://werkeninzorgenwelzijn.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/wzw.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://regioplus.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/regioplus.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://sigra.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/sigra.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://transvorm.org">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/transvorm.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://utrechtzorg.net">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/utrechtzorg.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://samenvoorbeterezorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/samen-voor-betere-zorg.png" class=" img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://viazorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/viazorg.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://wgvzorgenwelzijn.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/WGV_logo.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgenwelzijnzhz.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/wgv-zuid-holland.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgaanzet.org">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zorgaanzet.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgpleinnoord.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zorgplein-noord.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://flever.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/flever.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="this.$route.path.startsWith('/psychosociale-arbeidsbelasting')">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p>Deze online BeleidsSpiegels zijn ontwikkeld door JJ Knibbe en NE Knibbe (LOCOmotion) in opdracht van de Stichting RegioPlus in het kader van Gezond & Zeker.</p>
                    </div>
                </div>
                <div class="row" data-aos="fade-up">
                    <a class="col-md-2 col-4" target="_blank" href="https://www.zw-connect.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zw-connect.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://derotterdamsezorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/de-rotterdamse-zorg.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://blijfinzetbaar.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/gezondenzeker.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://locomotion.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/locomotion.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://werkeninzorgenwelzijn.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/wzw.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://regioplus.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/regioplus.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://sigra.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/sigra.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://transvorm.org">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/transvorm.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://utrechtzorg.net">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/utrechtzorg.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://samenvoorbeterezorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/samen-voor-betere-zorg.png" class=" img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://viazorg.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/viazorg.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://wgvzorgenwelzijn.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/WGV_logo.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgenwelzijnzhz.nl/">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/wgv-zuid-holland.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgaanzet.org">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zorgaanzet.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://zorgpleinnoord.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/zorgplein-noord.svg" class="img-fluid align-self-center">
                        </div>
                    </a>
                    <a class="col-md-2 col-4" target="_blank" href="https://flever.nl">
                        <div class="partner d-flex justify-content-center">
                            <img src="/img/partners/flever.png" class="img-fluid align-self-center">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Partner'
}
</script>
