var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-secondary pt-12 bg-circles-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[_c('h3',{staticClass:"color-primary"},[_vm._v("1. Uw huidige situatie: De RI&E als startpunt")]),_c('p',[_vm._v(" Meestal is het startpunt van het PSA-beleid de verplichte Risico-inventarisatie en -evaluatie (RI&E Zorg). De RI&E signaleert Arbo-risico’s. Komt psychosociale arbeidsbelasting als een knelpunt naar voren uit uw RI&E? ")]),_c('h4',[_vm._v("1. Psychosociale arbeidsbelasting")]),_vm._m(0),_c('h5',[_vm._v("1a. voor zorgverleners")]),_c('ChoiceField',{attrs:{"id":"1.a","options":[{
                            value: 0,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 1,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('h5',[_vm._v("1b. voor medewerkers van ondersteunde diensten")]),_c('ChoiceField',{attrs:{"id":"1.b","options":[{
                            value: 0,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 1,
                            label: 'Nee',
                            class: 'false'
                        }]}}),_c('h5',[_vm._v("1c. voor overige medewerkers")]),_c('ChoiceField',{attrs:{"id":"1.c","options":[{
                            value: 0,
                            label: 'Ja',
                            class: 'true'
                        }, {
                            value: 1,
                            label: 'Nee',
                            class: 'false'
                        }]}})],1)]),_c('transition',{attrs:{"name":"fade-in-up"}},[(((this.$store.getters['psychosocialStore/getQuestionById']('1.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.a').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('1.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.b').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('1.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.c').value !== null)))?_c('div',{staticClass:"hint row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"p-4 rounded bg-white box-shadow d-flex align-items-center"},[_c('span',{staticClass:"color-yellow rotate heading-font bold mr-3"},[_vm._v("Tip")]),_c('p',{staticClass:"mb-0 sub"},[_vm._v(" Er zijn geen wettelijke eisen gesteld aan de frequentie waarmee een RI&E moet worden uitgevoerd. De eis is dat de RI&E ‘actueel’ moet zijn. De RI&E kan eventueel ook op onderdelen (bijvoorbeeld alleen bepaalde afdelingen of werkplekken) geactualiseerd worden. ")])])])]):_vm._e()]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"btn-container text-center"},[_c('router-link',{staticClass:"btn btn-grad mt-3",attrs:{"to":{name: 'psychosocial-step-2'}}},[_vm._v("2. Uw algemene PSA-beleid"),_c('i',{staticClass:"fas fa-arrow-right"})])],1)])],1)]),_c('Progress')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5"},[_c('span',{staticClass:"color-yellow rotate heading-font bold"},[_vm._v("Toelichting")]),_c('div',{staticClass:"p-5"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Een RI&E signaleert slechts in globale zin Arbo-risico’s, zoals psychosociale arbeidsbelasting. Als dat zo blijkt te zijn, is het relevant om uw beleid psychosociale arbeidsbelasting nader onder de loep te nemen. U gaat bijvoorbeeld dan met deze BeleidsSpiegel PSA meer de diepte in. Maar ook als dat niet zo is, kan het relevant zijn om deze BeleidsSpiegel PSA verder in te vullen om wellicht nadere invulling te geven aan uw beleid. ")])])])}]

export { render, staticRenderFns }